<template>
  <div class="privacy-policy-container" id="page-content1">
    <div class="head">
      <h2 class="privacy-policy-title">개인정보처리방침</h2>
      <span class="privacy-policy-subtitle">privacy policy</span>
    </div>

    <div class="summary">
      <p>"(주)저스틴푸드 (이하 "회사"라 한다)회사가 운영하는 모든 서비스 사이트 이용자의 개인정보를 중요시하며, 
      "정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법"이라 함)" 및 "개인정보 보호법" 등 개인정보와 관련된 법령 상의 개인정보보호규정과 방송통신위원회 및 행정안전부 등 관계 부처가 제정한 가이드라인을 준수하고 있습니다. 
      회사는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하는 경우 반드시 사전에 이용자에게 해당 내용을 알리고 동의절차를 거치며, 
      이용자가 동의하지 않을 경우에는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하지 않습니다. 단, 동의를 거부하는 경우, 서비스의 전부 또는 일부 이용이 제한될 수 있습니다. 
      회사는 개인정보처리방침을 통해 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 
      적극적으로 보호하기 위해 항상 노력하고 있음을 알려드리고자 합니다. 
      본 방침은 2018년 1월 2일 부터 시행됩니다.</p>
    </div>

    <div class="category">
      <ul class="policy-categories">
        <li>1. 개인정보의 수집 및 이용</li>
        <li>2. 개인정보의 파기절차 및 방법</li>
        <li>3. 개인정보의 제3자 제공</li>
        <li>4. 이용자 및 법정대리인의 권리와 그 행사방법</li>
        <li>5. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</li>
        <li>6. 개인정보의 기술적, 관리적 보호 대책</li>
        <li>7. 개인정보에 관한 민원서비스</li>
        <li>8. 고지의 의무</li>
      </ul>
    </div>

    <div class="content">
      <div class="policy-section">
        <h4 class="section-title">1. 개인정보의 수집 및 이용</h4>
        <p class="section-content">회사는 최소한의 정보만을 필요한 시점에서 수집하며, 예약하기, 문의하기 등 기본적인 서비스 제공을 위한 필수정보용 개인정보를 수집하고 있습니다.
          <br><br>
          1) 케이터링 서비스 이용 고객(공통)<br>
          케이터링 문의시 (필수) : 서비스 제공 및 서비스 이용에 따른 기본정보 확인<br>
          -항목: 성명,이메일,휴대폰번호<br>
          -보유기간: 서비스 종료 시 까지<br>
          <br>
          이용자의 기본적 인권 침해의 우려가 있는민감한 개인정보(인종, 사상 및 신조, 정치적 성향이나 범죄기록, 의료정보 등)는 수집하지 않습니다.<br>
          <br>
          2) 법령에 의하여 수집&이용되는 회원 정보<br>
          서비스 이용 관련 개인정보(이용 기록, 접속 로그, 접속 IP Address) : 3개월_통신비밀보호법<br>
          전자금융 거래에 관한 기록 : 5년_전자금융거래법<br>
          소비자의 불만 또는 분쟁처리에 관한 기록 : 3년_전자상거래등에서의 소비자보호에 관한 법률<br>
          대금결제 및 재화 등의 공급에 관한 기록 :5 년_전자상거래등에서의 소비자보호에 관한 법률<br>
        </p>
      </div>
      
      <div class="policy-section">
        <h4 class="section-title">2. 개인정보의 파기절차 및 방법</h4>
        <p class="section-content">회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
          <br><br>
          - DB에 입력한 정보 등 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며
          문서 등 종이에 출력된 개인정보는 분쇄기로 파쇄합니다.<br>
          - 단, 내부관리계획 및 기타 관련 법령에 의한 사유("1. 개인정보의 수집 및 이용"에서 고지한 내용 참조)에 해당하는 경우 일정 기간 저장된 후 파기됩니다.
        </p>
      </div>
      
      <div class="policy-section">
        <h4 class="section-title">3. 개인정보의 제3자 제공</h4>
        <p class="section-content">회사는 이용자들의 개인정보를 "1. 개인정보의 수집 및 이용"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나
원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 단, 다음의 경우에는 개인정보를 이용 및 제공할 수 있습니다.
법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구(영장 발부 등)가 있는 경우 개인정보의 제3자 제공에 대한
별도 동의 없이 제공할 수 있습니다.
        </p>
      </div>

      <div class="policy-section">
  <h4 class="section-title">4. 이용자 및 법정대리인의 권리와 그 행사방법</h4>
  <p class="section-content">
    1) 정보주체는 회사에 대해 언제든지 다음 각 호의 개인 정보 보호 관련 권리를 행사할 수 있습니다.
    <ul>
      <li>개인 정보 열람 요구</li>
      <li>오류 등이 있을 경우 정정요구</li>
      <li>삭제 요구</li>
      <li>처리정지 요구</li>
    </ul>

    2) 제1항에 따른 권리행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하려 하실 수 있으며 회사는 이에 대해 지체 없이 조 취하겠습니다.

    3) 정보주체가 개인 정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인 정보를 요구하거나 제공하지 않습니다.

    4) 제1항에 따른 권리행사는 정보주체의 법정 대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 11호 서식에 따른 위임장을 제출하셔야 합니다.

    5) 정보주체는 개인정보보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인 정보 및 사생활을 침해하여서는 아니 됩니다.
  </p>
</div>

<div class="policy-section">
  <h4 class="section-title">5. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h4>
  <p class="section-content">
    1) 쿠키(Cookie)의 정의<br>
    쿠키(Cookie)는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터로 전송하는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장되고 있습니다. 따라서 여러분 스스로가 쿠키의 설치 및 수집에 대해 자율적으로 선택할 수 있으므로 수집을 거부할 수 있습니다. 다만, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스의 이용에 제한이 생길 수 있습니다.
    <br><br>
    2) 쿠키 설정 거부 방법<br>
    - Internet Explorer의 경우 웹 브라우저 상단의 "도구" 메뉴 &gt; "인터넷 옵션" 메뉴 &gt; "개인정보" 탭 &gt; 직접설정<br>
    - Chrome의 경우 웹 브라우저 우측 상단의 "설정" 선택 &gt; "설정" 화면 하단의 "고급" 설정 버튼 선택 &gt;
    개인정보 및 보안 섹션의 "콘텐츠 설정" 버튼 &gt; "쿠키" 섹션에서 직접 설정
  </p>
</div>

<div class="policy-section">
  <h4 class="section-title">6. 개인정보의 기술적, 관리적 보호 대책</h4>
  <p class="section-content">
    회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적, 관리적 대책을 강구하고 있습니다.
    <br><br>
    1) 개인정보 암호화<br>
    회사는 이용자의 개인정보를 암호화하여 저장 관리하고 있습니다.
    <br><br>
    2) 개인정보취급자 및 전사 차원의 교육<br>
    개인정보관련취급자 및 전 직원에 대해 새로운 보안위협 및 개인정보보호 제도에 관해 정기적인 교육을 실시하고 있습니다.
  </p>
</div>

<div class="policy-section">
  <h4 class="section-title">7. 개인정보에 관한 민원서비스</h4>
  <p class="section-content">
    회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보보호책임자를 지정하고 있으며,
    모든 개인정보보호 관련 민원을 개인정보보호 책임자 혹은 관리자, 담당자에게 신고 할 수 있습니다.
    <br><br>
    회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
    <br><br>
    개인정보책임자 :<br>
    이선택 상무<br>
    연락처 02-3144-8783<br>
    <br>
    개인정보관리 및 담당자:<br>
    이선택 상무<br>
    연락처 02-3144-8783
  </p>
</div>

<div class="policy-section">
  <h4 class="section-title">8. 고지의 의무</h4>
  <p class="section-content">
    현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최초 7일전부터 홈페이지의 ‘공지사항’을 통해 고지 할 것입니다.
    다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자의 권리의 중요한 변경이 있을 경우에는 최소 30일전에 고지합니다.
    <br><br>
    - 공고일자 2019년 작업 적용완료일<br>
    - 시행일자 2019년 작업 적용완료일
  </p>
</div>

      
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped>
.privacy-policy-container {
  font-family: 'NanumSquare', 'Nanum Gothic', sans-serif;
  font-weight: 400;
  background-color: #fff;
  color: #525252;
  word-break: keep-all;
  padding: 20px;
}

.privacy-policy-title {
  text-align: left;
  margin-bottom: 12px;
  font-size: 30px;
  line-height: 40px;
  color: #242424;
}

.privacy-policy-subtitle {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  font-size: 14px;
  color: #242424;
}

.summary {
  margin-bottom: 20px;
  line-height: 26px;
}

.policy-categories {
  padding: 0;
  list-style-type: none;
  margin-bottom: 20px;
}

.policy-categories li {
  font-size: 18px;
  margin-bottom: 8px;
}

.content {
  margin-top: 20px;
}

.policy-section {
  margin-bottom: 30px;
  line-height: 26px;
}

.section-title {
  margin-bottom: 8px;
  font-size: 17px;
  line-height: 26px;
  color: #242424;
}

.section-content {
  margin-bottom: 18px;
  color: #525252;
  line-height: 26px;

}

a {
  color: #bfaa88;
}

a:hover,
a:focus {
  outline: 0;
  color: #bfaa88;
  text-decoration: none;
}

img {
  max-width: 100%;
}

ul,
ol {
  padding: 0;
  list-style-position: inside;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 20px;
}

dl {
  margin-bottom: 20px;
}
</style>
