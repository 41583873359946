
import { createRouter, createWebHistory } from 'vue-router'
import MainComponent from './components/Main.vue'
import Group from './components/Group.vue'
import History from './components/History.vue'

import FamilyChannel from './components/FamilyChannel.vue'

import YouTube from './components/YouTube.vue'

import Recruitment from './components/Recruitment.vue'
import Directions from './components/Directions.vue'
import Contact from './components/Contact.vue'
import PrivacyPolicy from './components/PrivacyPolicy.vue'
import MarketingPolicy from './components/MarketingPolicy.vue'
import Brand from './components/Brand.vue'
import News from './components/News.vue'
import Magazine from './components/Magazine.vue'
import Portfolio from './components/Portfolio.vue'
import Partners from './components/Partners.vue'
import About from './components/About.vue'
import Test from './components/Test.vue'

const history = createWebHistory()

const router = createRouter({
  history,
  routes: [
    {
      path: '/',
    component: MainComponent,
    props: true

    },
 
 
    {
      path: '/history',
      component: History
    },
    {
      path: '/group',
      component: Group
    },
    {
      path: '/brand',
      component: Brand,
      props: true,
    },
    {
      path: '/news',
      component: News,
      props: true, 
    },
    {
      path: '/magazine',
      component: Magazine,
      props: true,
    },
    {
      path: '/portfolio',
      component: Portfolio,
      props: true,
    },
    {
      path: '/partners',
      component: Partners,
      props: true,
    },
    {
      path: '/family-channel',
      component: FamilyChannel
    },
 
    {
      path: '/youtube',
      component: YouTube
    },

    {
      path: '/recruitment',
      component: Recruitment
    },

    {
      path: '/directions',
      component: Directions
    },
    {
      path: '/contact',
      component: Contact
    }
    ,
    {
      path: '/privacy-policy',
      component: PrivacyPolicy
    }
    ,
    {
      path: '/marketing-policy',
      component: MarketingPolicy
    }
    ,
    {
      path: '/about',
      component: About
    }
    ,
    {
      path: '/test',
      component: Test
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})
export default router