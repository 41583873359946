<template>
  <div class="Directions">
    <div class="topBanner container">
      <h1 data-aos="fade-up" data-aos-duration="1500">{{ title }}</h1>
  
    </div>
    <div class="DirectionsCon container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
      <div class="DirectionsImg" :style="{ backgroundImage: 'url(' + directionsImg + ')' }"></div>
      <div class="DirectionsGroup">
        <div class="DirectionsMap" id="map"></div>
        <div class="DirectionsTextGroup">
          <p class="DirectionsTit f6">
   
            {{ DirectionsTit[0] }}
          </p>
          <p class="DirectionsSub f6">{{ DirectionsSubCar }}</p>
        </div>
        <div class="DirectionsTextGroup">
          <p class="DirectionsTit f6">
            <span><img :src=logo[1]></span>
            {{ DirectionsTit[1] }}
          </p>
          <p class="DirectionsSub f6"><span>{{ DirectionsSubway[0] }}</span><i>{{ DirectionsSubway[1] }}</i></p>
          <p class="DirectionsSub f6"><span>{{ DirectionsSubway02[0] }}</span><i>{{ DirectionsSubway02[1] }}</i></p>
        </div>


        <div class="DirectionsTextGroup">
          <p class="DirectionsTit f6">
            <span><img :src=logo[2]></span>
            {{ DirectionsTit[2] }}
          </p>
          <div v-for="(buses, busType) in DirectionsSubBus" :key="busType" class="DirectionsSub f6">
            <span :class="{'DirectionsBus': true, 'blue': busType === '간선버스', 'green': busType === '지선버스'}">
              {{ busType }}
            </span>
            <div>
              <span v-for="(bus, index) in buses" :key="index">{{ bus }}<span v-if="index < buses.length - 1">, </span></span>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DirectionsComponent',
  data(){
    return{
      logo:['',require('/public/img/Directions2.png'), require('/public/img/Directions3.png')],
      title:"오시는 길",
      map: null,
      directionsImg:require('/public/img/pc_directions.png'),
      DirectionsTit:['주소','지하철 이용시','버스 이용시'],
      DirectionsSubCar:'서울특별시 마포구 월드컵북로 128, 1층',
      DirectionsSubway:['마포구청역 6호선','3번출구 10분 거리 약 800m'],
      DirectionsSubway02:['경의중앙선 가좌역','1번출구 10분 거리 약 800m'],
      DirectionsSubBus:{'간선버스':[171, 172, 601, 606, 672, 673, 674, 710],'지선버스':['6714', '7013A', '7013B', '7016', '7711', '7737', '마포08', '마포15'
      ]},

    

    }
  },
  mounted() {
    if (window.kakao && window.kakao.maps) {
      this.loadMap();
    } else {
      this.loadScript();
    }
  },
  methods: {
    loadScript() {
      const script = document.createElement("script");
      script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=da43ba87e243a377fdcea3dd6e345d31&autoload=false"; 
      script.onload = () => window.kakao.maps.load(this.loadMap); 

      document.head.appendChild(script);
    },
    loadMap() {
      const container = document.getElementById("map"); 
      const options = {
        center: new window.kakao.maps.LatLng(37.5645426145028, 126.912804389144), 
        level: 3
      };

      this.map = new window.kakao.maps.Map(container, options); 
      this.loadMaker();
    },
    // 지정한 위치에 마커 불러오기
    loadMaker() {
      const markerPosition = new window.kakao.maps.LatLng(
        37.5645426145028,
        126.912804389144
      );

      const marker = new window.kakao.maps.Marker({
        position: markerPosition,
      });

      marker.setMap(this.map);
    },
  },
}
</script>
<style scoped>
  .DirectionsCon{display:flex; justify-content: space-between; align-items: flex-start;}
  .DirectionsImg{width:49%; margin-right:0.5%;
    aspect-ratio: 1/1.2;
    border-radius:2rem; background-repeat: no-repeat; background-size: cover;}
  .DirectionsGroup{width:49%; margin-left:0.5%;}
  .DirectionsMap{width:100%;
    aspect-ratio: 1/0.5;
    background: #ddea4b;  border-radius:1rem; }
  .DirectionsTextGroup{margin: 50px 0;}
  .DirectionsTit{font-weight:900; margin-bottom:30px;}
  .DirectionsTit span{vertical-align: middle; margin-right:5px;} 
  .DirectionsSub{color:#5a5a5a; font-weight:500;display: flex;
    flex-wrap: wrap;
    align-items: center;}
  .DirectionsTextGroup:nth-child(2) .DirectionsTit{margin-bottom:15px;}

  .DirectionsTextGroup:nth-child(3) .DirectionsSub span{padding:10px 20px; color:#fff; background:#b5500c; border-radius:40px; margin-right:15px;}
  .DirectionsTextGroup:nth-child(3) .DirectionsSub:last-child{margin-top:15px; }
  .DirectionsTextGroup:nth-child(3) .DirectionsSub:last-child span{background:#77C4A3;}
  .DirectionsTextGroup .DirectionsSub .DirectionsBus{padding:10px 25px; color:#fff; background:#dcdcdc; border-radius:30px; margin-right:15px; font-weight: 600;}
  .DirectionsTextGroup .DirectionsSub .DirectionsBusNum{display: flex;
    align-items: center;
    justify-content: flex-start;}
  .DirectionsTextGroup .DirectionsSub .DirectionsBusNum span{
    display: inline-block;
    color:#fff; border-radius:8px; margin:0 3px; text-align:center; width:5rem; height:2rem; line-height:2rem; font-size:1.2rem;
  }
  .DirectionsTextGroup .DirectionsSub .DirectionsBusNum p{padding:0; margin:0;width: fit-content;}
  .DirectionsTextGroup .DirectionsSub .blue{background:#0143e2;}
  .DirectionsTextGroup .DirectionsSub .green{background: #02b400;}
  .DirectionsTextGroup .DirectionsSub:last-child{margin-top:15px;}
  .w680{display:none;}
  
  @media screen and (max-width:1400px){
    .DirectionsCon .DirectionsImg{
      aspect-ratio: 1 / 1.5;
    }
  }
  @media screen and (max-width:990px){
    .DirectionsCon span{
      flex-direction: column;
    }

    .DirectionsCon .DirectionsImg{
      width: 100%;
      background-position: center;
      aspect-ratio: 2 / 1;
      display: none;
    }
    .DirectionsGroup{
      width:100%;
      margin:0;
    }
  }

  
  @media screen and (max-width:680px){
    .DirectionsTextGroup .DirectionsSub i{
      margin-top:2rem;
    }
  
    }

    @media screen and (max-width:360px){
    .DirectionsTextGroup .DirectionsSub{
      display: flex;
    flex-direction: column;
    align-items: flex-start;
  
 
    }
    .DirectionsTextGroup .DirectionsSub > div{
      margin-top:1rem;
      margin-left: 1rem;
    }
  }
</style>

