<template>
  <div class="partners-container container">
    <ul class="FamilyChannelList aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
      <li v-for="(item, index) in business" :key="index" class="FamilyChannelListbox">
        <a :href="item.path" class="f7 b5">{{ item.name }}</a>
      </li>
    </ul>


    <h2 class="f11 b9 pt4">JUSTIN PARTNERS</h2>




    <div class="mainSection reveal" data-sr-id="0" style="visibility: visible; opacity: 1; transition: all, opacity 1.5s cubic-bezier(0.5, 0, 0, 1) 0.2s;">
    <div class="mainContainer">
        <div class="topTitGroup">
            <p class="subTxt" style="font-weight: 700; margin-bottom: 3%;">OUR STORY</p>
            <div class="countGroup">
                <div class="countForm">
                    <p class="countTxt">MENU</p>
                    <p class="num"><span class="number1">60</span><span class="plus">+</span></p>
                </div>
                <div class="countForm">
                    <p class="countTxt">BRANDS</p>
                    <p class="num"><span class="number2">1,500</span><span class="plus">+</span></p>
                </div>
                <div class="countForm">
                    <p class="countTxt">OFFERS</p>
                    <p class="num num3"><span class="number3">3,000</span><span class="plus">+</span></p>
                </div>
            </div>
        </div>
        <div class="imgGroup pcShow">
            <div class="imgForm">
                <img src="/img/partner.jpg">
            </div>
       
        </div>

        <div class="TxtContainer reveal mt3 mb3" style="text-align: center; visibility: visible; opacity: 1; transition: all, opacity 1.5s cubic-bezier(0.5, 0, 0, 1) 0.2s;" data-sr-id="2">
            <p class="subTxt" style="font-weight: 700; margin-bottom: 5px; color: #000;">OUR PARTNERS</p>
            <p style="font-size: 27px; color: #000;">저스틴푸드와 함께한 기업입니다</p>
        </div>
    </div>

</div>






    <div class="category-selector">
      <button @click="filterCategory = ''" :class="{ 'active-tab': filterCategory === '' }">전체 보기</button>
      <button v-for="category in categories" :key="category" @click="filterCategory = category" :class="{ 'active-tab': filterCategory === category }">
        {{ category }}
      </button>
    </div>
    <div class="bordertop1 mt2"></div>
    <section class="mt2">
      <ul class="partners-list">
        <li v-for="image in displayedImages" :key="image.idx" class="portfolio-item" data-aos="fade-up" data-aos-duration="1500">
          <img :src="getImagePath(image.title_img)" :alt="image.title" class="partner-logo">
        </li>
      </ul>
    </section>
    <div ref="infiniteScrollAnchor"></div>
  </div>
</template>



<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'PartnersComponent',
  props: {
    business: Array,
  },
  setup() {
    const images = ref([]);
    const filterCategory = ref('');

    const adjustCategoryName = (category) => {
      switch (category) {
        case '관공서': return '관공서/금융기관';
        case '럭셔리브랜드': return '럭셔리';
        case 'smallImg': return null;
        default: return category;
      }
    };

    const categories = computed(() => {
      return [...new Set(images.value.map(image => adjustCategoryName(image.catagory)))].filter(c => c);
    });

    const displayedImages = computed(() => {
      if (filterCategory.value === '') {
        return images.value;
      }
      return images.value.filter(image => adjustCategoryName(image.catagory) === filterCategory.value);
    });

    const getImagePath = (path) => `https://justincatering.com${path.replace('../', '/')}`;

    const loadImages = async () => {
      try {
        const response = await axios.post('https://justincatering.com/api/justinfood.php', {
          path: 'partner', value: 'logo', page: 1
        });
        images.value = response.data;
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    onMounted(() => {
      AOS.init();
      loadImages();
    });

    return { categories, displayedImages, getImagePath, filterCategory };
  }
};
</script>


<style scoped>
.imgForm img{
  width: 100%;
}
.active-tab {
  border-bottom: 2px solid var(--color-sub) !important;
}

.partners-container {

  margin-top: 7%;
}
.FamilyChannelListbox{
  margin-top: 8vh;
}

.partners-list {
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
  margin-left: 1.75%;
  margin-right: 1.75%;

}
.portfolio-item {
  width: 12.5%;
  margin-right:1.5%;
  margin-top:15px;
}
/* 6,12,18,24,30,36,42,48,54,60 */
.portfolio-item:nth-child(7n) {
  margin-right: 0;
}

.partners-list li{
  
}
.partner-logo {
  width: 100%;
  height: auto;
  border: 1px solid #ccc; /* 이미지 주위에 테두리 추가 */

  padding: 5px; /* 이미지와 테두리 사이에 약간의 공간 추가 */
}
.category-selector{
  display: flex;
    align-content: center;
    align-items: center;
}
.category-selector button {
  margin: 5px;
  padding: 10px;
  width: fit-content;
  border: none; /* 기존 테두리 제거 */
  background-color: transparent; /* 배경색 제거 */
  cursor: pointer;
  transition: border-color 0.3s; /* 부드러운 색상 전환 효과 */
  font-size:1.2rem;
  font-weight: 900;
  margin-left: 2%;
}

.mainSection {
    background: linear-gradient(0deg, #fff 60%, #ffedeb 40%);
    padding: 2% 0 0;
    width: 100%;
    margin: 0 auto;
    margin-top:6rem;
}

.topTitGroup {
    text-align: center;
    color: #000;
}

.imgGroup {
    display: flex;
    align-items: center;
    justify-content: center;
}

.num {
    font-size: 40px;
    font-weight: 700;
}
.countTxt {
    font-size: 30px;
    line-height: 1.1;
    margin-bottom: 0;
}
.countForm {
    margin: 0 1.5%;
    flex: 1;
}

.countGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 700px;
    margin: 0 auto 3%;
}
.subTxt {
    font-size: 37px;
    font-weight: 100;
    line-height: 1.2;
}

@media (max-width: 768px) {
  .partners-list {
  margin-left: 0;
  margin-right: 0;

}
.portfolio-item {
  width: 48%;
  margin-right:4%;
 
}
.portfolio-item:nth-child(7n) {
  margin-right: 4%
}
.portfolio-item:nth-child(2n) {
  margin-right: 0!important;
}

.category-selector button {
    margin: 0;
    padding: 0;
    width: fit-content;
    font-size: 1.2rem;

    margin-left: 0;
}
.category-selector{
    justify-content: space-between;
}
.countGroup *{
  font-size: 2rem;
}

}

@media (max-width: 340px) {
  .countGroup *{
  font-size: 1.5rem;
  }
  .subTxt{
    font-size: 2rem;
  }
}
</style>
