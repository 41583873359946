<template>
  <div class="brandPage container pt1">
      <ul class="FamilyChannelList aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
          <li v-for="(item, index) in business" :key="index" class="FamilyChannelListbox">
              <a :href="item.path" class="f7 b5">{{ item.name }}</a>
          </li>
      </ul>
      <h1 class="f11 b9 pb4 pt4">JUSTIN BRAND</h1>

      <ul class="brand">
          <li v-for="(logo, index) in currentSiteLogos"
              :data-aos="`fade-up`"
              :data-aos-duration="1500"
              :data-aos-delay="`${index + 3}00`"
              :key="index"
              @mouseover="logoHoverIn"
              @mouseout="logoHoverOut"
              @click="handleClick(index)">
              <div class="logo-wrapper">
                  <img :src="logo" alt="logo">
                  <div class="hoverTextGroup f2">
                      <p class="f6">{{ companyInfo[index].type }}</p>
                      <div class="lineBar"></div>
                      <div class="companyInfo f4" v-html="companyInfo[index].info"></div>
                  </div>
              </div>
          </li>
      </ul>
  </div>
</template>

<script>
export default {
  name: 'BrandComponent',
  props: {
    siteLogo: Array,
    siteLogo2: Array,
    icon: Object,
    companyInfo: Array,
    business: Array,
  },
  computed: {
    currentSiteLogos() {
      return window.innerWidth <= 990 ? this.siteLogo : this.siteLogo2;
    },
  },
  methods: {
    logoHoverIn(e) {
      // 현재 요소 내 자식 중 .hoverTextGroup의 opacity를 1로 변경
      e.currentTarget.querySelector('.hoverTextGroup').style.opacity = 1;
    },
    logoHoverOut(e) {
      e.currentTarget.querySelector('.hoverTextGroup').style.opacity = 0;
    },
    handleClick(index) {
      const link = this.companyInfo[index].link;
      if (link) {
        // 링크 새창으로 열기
        window.open(link);
      }
    }
  }
}
</script>

<style scoped>
.companyInfo{
max-width: 85%;
margin: 0 auto;
text-align: center;
line-height: 1.3rem;
}

.brandPage {
  margin-top: 7rem;
}

.allbrand {
  padding: 2% 5%;
  background-color: #ffcfcf;
  border-radius: 2rem;
  position: relative;
  top: -5vh;
  text-align: right;
}

.brand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.brand li {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32%;
  border-radius: 100%;
  box-shadow: 0 0 0.3rem 0.1rem rgba(0, 0, 0, 0.1);
  aspect-ratio: 1;
  max-width: 300px;
  cursor: pointer;
  position: relative;
}

.brand li img {
  max-width: 180px;
  width: 100%;
}



.hoverTextGroup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  aspect-ratio: 1;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 100%;
  flex-direction: column;

}

.hoverTextGroup .lineBar {
  height: 1px;
  width: 10%;
  margin: 6% auto;
  background: #fff;
}

.hoverTextGroup p {
  width: 80%;
  word-break: keep-all;
  text-align: center;
  line-height: 1.2rem;
}

.brand li:hover .hoverTextGroup {
  opacity: 1;
}
@media screen and (max-width: 990px) {
  .brand{
    flex-direction: column;
    justify-content: center!important;
    align-items: center;
    margin: 0 auto;
    max-width: unset;
      
    }
    .brand li{
      width: 40%;
      margin-bottom: 4rem;

    }
}
@media screen and (min-width: 990px) {
  .brand li img{
        transform: translateY(25%);
}
}

@media screen and (max-width: 480px){

  .brand li{
  width: 60%;
}
}


@media (prefers-color-scheme: dark){
  .brand li{
    border: 1px solid #fff;
  }

}
</style>