<template>
<div class="newsPage container" >
  <div class="pb1"></div>
  <ul class="FamilyChannelList aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
      <li v-for="(item, index) in Mediahub" :key="index" class="FamilyChannelListbox">
        <a :href="item.path" class="f7 b5">{{ item.name }}</a>
      </li>
    </ul>
    <h2 class="left f11 b9 pt4 pb4">JUSTIN NEWS</h2>
  <ModalComponent :show="showModalId !== null" :close-modal="closeModal" :select-posts="selectPosts" />


    <div id="filter" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
      <select class="categoryFilter" @change="handleCategoryChange" ref="categoryFilter" style="display: none;">
        <option value=""  selected >{{ NewsCategoryFilter[0] }}</option>
        <option :value="NewsCategoryFilter[1]">{{ NewsCategoryFilter[1] }}</option>
        <option :value="NewsCategoryFilter[2]">{{ NewsCategoryFilter[2] }}</option>
        <option :value="NewsCategoryFilter[3]">{{ NewsCategoryFilter[3] }}</option>
        <option :value="NewsCategoryFilter[4]">{{ NewsCategoryFilter[4] }}</option>
      </select>
      <select  class="dateFilter" @change="handleDateChange"  ref="dateFilter">
        <option value=""  selected >{{ NewsDateFilter[0] }}</option>
        <option value="newest">{{ NewsDateFilter[1] }}</option>
        <option value="oldest">{{ NewsDateFilter[2] }}</option>
      </select>
      <div class="searchFilter">
        <input type="text" placeholder="검색어를 입력해주세요." ref="searchFilterInput">
        <button type="button" >
          <i class="material-symbols-rounded f8" @click="search">{{ this.icon.icon[2] }}</i>
        </button>
      </div>
    </div>
    <ul class="newsComponent newsButton">
    <li v-for="(image, index) in newsPosts" :key="index" @click="handleClick(image)" data-aos="fade-up" data-aos-duration="1500" :data-aos-delay="'200'">
      <div class="newsComponent_cover" :style="{ backgroundImage: 'url(https://justincatering.com' + image.news_thumb.replace('../', '/') + ')' }"></div>
      <div class="businessPost">
        <h3 class="f5 b8">{{ image.news_title }}</h3>
        <div class="businessPost_info">
          <p>{{ image.type }}</p>
          <div class="btn01 f2" style="background: #f4f4f4; width:50%;">{{ NewsViewMore }}</div>
        </div>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
import ModalComponent from './ModalComponent.vue';
import axios from 'axios';

export default {
  name: 'NewsComponent',
  components: {
    ModalComponent,
  },
  props:{
    icon: Object,
    Mediahub: Array,
  },
  created(){
    axios.post('https://justincatering.com/api/justinfood.php',{ path: 'brand',value:'news' }).then(response => {
      this.newsPosts = response.data;
      
    })
  },
  data() {
    return {
      newsPosts: [],
      selectPosts: [],
      NewsCategoryFilter:['전체','저스틴케이터링','저스틴스테이크','더셀렉션','더셀렉션 미트'],
      NewsDateFilter:['전체','최신순','오래된순'],
      NewsViewMore:'VIEW MORE',
      
    }
  }
  ,
 
  methods: {
    handleClick(image) {
    if (image.link) {
      // link 컬럼 값이 있을 경우 새 창으로 이동
      window.open(image.link, '_blank');
    } else {
      // link 컬럼 값이 없을 경우 모달 창을 보여줌
      this.showModal(image.idx);
    }
  },
    showModal(imageId) {
      this.showModalId = imageId;
      this.selectPosts = this.newsPosts.filter((item) => {
        return item.idx == imageId;
      });
      this.selectPosts = this.selectPosts[0];
  
      document.querySelector('.newsButton').style.display = 'none';
      document.querySelector('.newsModal').style.display = 'flex';

    },
    closeModal() {
      this.showModalId = null;
      document.querySelector('.newsButton').style.display = 'flex';
      document.querySelector('.newsModal').style.display = 'none';
    },
    search() {
  axios.post('https://justincatering.com/api/justinfood.php',{ path: 'brand',value:'news' }).then(response => {
    this.newsPosts = response.data;
    this.newsPosts = this.newsPosts.filter((item) => {
      return item.news_title.includes(this.$refs.searchFilterInput.value);
    });
  });
},
    handleCategoryChange() {
  if(this.$refs.categoryFilter.value){

    axios.post('https://justincatering.com/api/justinfood.php',{ path: 'brand',value:'news' }).then(response => {
      this.newsPosts = response.data;
      this.newsPosts = this.newsPosts.filter((item) => {
        return item.type.includes(this.$refs.categoryFilter.value); 
      });
    })
  }
  else{
    axios.post('https://justincatering.com/api/justinfood.php',{ path: 'brand',value:'news' }).then(response => {
      this.newsPosts = response.data;
    })
  }
},
handleDateChange() {
  const parseDate = (str) => {
    const [date, time] = str.split(' ');
    const [year, month, day] = date.split('.');
    const [hour, minute, second] = time.split(':');
    return new Date(year, month - 1, day, hour, minute, second).getTime();
  }

  if(this.$refs.dateFilter.value){
    if(this.$refs.dateFilter.value == 'newest'){
      this.newsPosts = this.newsPosts.sort((a, b) => {
        return parseDate(b.time) - parseDate(a.time);
      });
    } else {
      this.newsPosts = this.newsPosts.sort((a, b) => {
        return parseDate(a.time) - parseDate(b.time);
      });
    }
  } else {
    this.newsPosts = this.newsPosts.sort((a, b) => {
      return parseDate(b.time) - parseDate(a.time);
    });
  }
}

  },

}

</script>



<style scoped>

.newsModal {
  display: none;
  /* 모달 스타일 설정 */
}
.newsButton{
  display: flex;
  flex-wrap: wrap;
}

#filter{

  display: flex;
    justify-content: space-between;

    margin-bottom: 20px;

  width: 96%;

    align-items: center;
    

}


.categoryFilter , .dateFilter{
  width: 20%;
}
.searchFilter{
  width: 50%;
  position: relative;
}
.searchFilter input{
  width: 100%;

}
.searchFilter button{
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  position: absolute;
  right: 1vw;
  top: 50%;
  transform: translateY(-50%);
}
.categoryFilter , .dateFilter, .searchFilter input{

  padding: 1rem 0;
  padding-left:1rem;
  border-radius: 1.5rem;
  border:0;
  outline: none;
  /* 그림자 */
  box-shadow: 0 0 0.5rem rgba(0,0,0,0.1);
  font-weight: 800;
}

.businessFilter ul:nth-child(2) {
  padding-top:5px;
  padding-left: 10px;

    padding-bottom:2%;
}
.businessFilter ul:nth-child(2)::-webkit-scrollbar {
  width: 10px;
}

.businessFilter ul:nth-child(2)::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.businessFilter ul:nth-child(2)::-webkit-scrollbar-thumb {
  background-color: #ffcfcf;
  border-radius: 5px;
  
}

.businessFilter ul:nth-child(2)::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  
}


.newsComponent img{
  width: 100%;
  height: auto;
  border-radius: 0.9rem 0.9rem 0 0;
}
.newsComponent li{
  cursor: pointer;
 
  width: 32%;
  margin-right: 2%;
  
  box-shadow: 0 0 0.5rem rgba(0,0,0,0.2);
  border-radius: 0.9rem;
margin-bottom:3rem;
}
.newsComponent li:nth-child(3n){
  margin-right: 0;
}

.businessPost{

  padding: 1rem;

}
.businessPost h3{
  /* 2줄뒤 ... 처리 */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  
  line-height: 1.6rem;
}
.businessPost_info{
 
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 0.5rem 0;
}
.businessPost_info .btn01:hover{
  background-color: #ffcfcf;
}
.newsComponent_cover{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 1 / 1;
  border-radius: 1rem 1rem 0 0;
 width: 100%;

}
.newsComponent_cover:hover{
  opacity: 0.8;
}

@media screen and (max-width: 990px) {
  .newsButton{
    justify-content: space-between;
}
  .newsComponent li{
    width: calc(50% - 10px);
    margin-left: 0;
    margin-right: 0;
  }
  .newsComponent li:nth-child(2n+1){
    margin-left: 0;
    
  }
  
  .businessPost h3{
 
  line-height: 2rem;
}
  #app .newsModal {
    margin: 0 auto;
    width: 93%;}


  #filter{
    width:100%;
    flex-wrap: wrap;
  
  }
  #filter select{
    width: 48%;
    margin-bottom: 2rem;

  }
  #filter .searchFilter{
    width: 100%;
  }
  #filter .searchFilter input{
    width: calc(100% - 1rem);
  }

  #app .newsComponent_cover{
    height: 40vh;
  }
  #app .businessView .newsComponent{
    position: relative !important;
    margin-top:12vh !important;
  }
  
}

@media screen and (max-width: 680px) {
  #app .newsComponent_cover{
    height: 25vh;
  }
  
}

@media screen and (max-width: 480px){
  
  #app .newsComponent_cover{
    height: 35vh;
  }
  .newsComponent li{
    width: 100%;
    margin-left: 0;
  }
}

</style>