<template>
  <div class="App">
    <Header :logo="logo" :categories="categories" />
    <div class="body-container">
      <router-view :business="categories[1]['children']" :Mediahub="categories[2]['children']" 
      :icon="icon" :siteLogo2="siteLogo2" :siteLogo="siteLogo" :companyInfo="companyInfo" :sns="sns" />
    </div>
    <Footer :logo="logo" />
  </div>
</template>

<script>

import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

import AOS from 'aos';
import "aos/dist/aos.css";

import './components/common.css';

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require('/public/img/logo.png'),
      icon:{prev: require('/public/img/prev.png'),next: require('/public/img/next.png'), icon:['arrow_forward_ios','add','search']},
      sns: {
        instagram: require('/public/img/sns01.png'),
        facebook:  require('/public/img/sns05.png'),
        
        youtube: require('/public/img/sns02.png'),
        blog: require('/public/img/sns03.png'),
        kakaotalk: require('/public/img/sns04.png'),
        smartstore: require('/public/img/sns06.png'),
      },
      siteLogo: [require('/public/img/logo02.png'), require('/public/img/logo04.png'), require('/public/img/logo05.png')],
      siteLogo2: [require('/public/img/_logo02.png'), require('/public/img/_logo04.png'), require('/public/img/_logo05.png')],
      companyInfo: [
      {
        type: '저스틴케이터링',
        info: `<p>행사 전문 파티플래너와 최고의<br>플로리스트, 수석쉐프와 공간연출까지.<br>최고급 ONE-STOP 케이터링 서비스를 <br>제공해 드립니다.</p>`,
        link: 'https://justincatering.com',
        sns: {
          youtube: 'https://www.youtube.com/watch?v=ofISZp4HoPc&list=PLA9e9H_S4E8e4oBzevcAYavC4aunqXGFn',
          instagram: 'https://www.instagram.com/justincatering/',
          blog: 'https://blog.naver.com/jwpresident'
        }
      },
      {
        type: '저스틴스테이크 기프트',
        info: `<p>이태원 맛집 ‘저스틴스테이크’의<br> 최고급 숙성 한우를 집에서! <br>저스틴스테이크 헤드 쉐프가 <br>엄선한 바로 그 맛.</p>`,
        link:'http://smartstore.naver.com/justinsteak',
        sns: {
          instagram: 'https://www.instagram.com/theselection_meat/',
          smartstore: 'https://smartstore.naver.com/justinsteak'
        }
      },
      {
        type: '저스틴푸드마켓',
        info: `<p>저스틴만의 노하우로 직접 기획,<br> 제작하는 다양한 상품을 제공합니다.</p>`,
        link: 'https://smartstore.naver.com/justinfoodmarket',
        sns: {
          youtube: 'https://www.youtube.com/watch?v=ofISZp4HoPc&list=PLA9e9H_S4E8e4oBzevcAYavC4aunqXGFn',
          instagram: 'https://www.instagram.com/justincatering/',
          smartstore: 'https://smartstore.naver.com/justinfoodmarket/'
        }
      }
    ],
      categories: [
        {
          name: 'ABOUT',
          path: '/about',
          children: [],
          showSubmenu: false
        },
        {
          name: 'BUSINESS',
          path: '/brand',
          children: [ { name: '브랜드',  path: '/brand'},{name: '포트폴리오',path: '/portfolio'},{name: '이용고객사',path: '/partners'}],
          showSubmenu: false,
          
        },
        {
          name: 'MEDIA HUB',
          path: '/youtube',
          children: [  { name: '유튜브', path: '/youtube' }, { name: '뉴스', path: '/news'}],
          showSubmenu: false
        },
        {
          name: 'RECRUIT',
          path: '/recruitment',
          children: [],
          showSubmenu: false
        },
        {
          name: 'CONTACT',
          path: '/contact',
          children: [  {  name: '문의하기',   path: '/contact'},{  name: '오시는길',   path: '/directions'}],
          showSubmenu: false
        }
      ]
   
    };
  },
  created() {
    AOS.init();
    },


};
</script>