<template>
  <div class="about container pc1100">
      <div class="aboutPage">
          <div class="section01">
              <img src="/img/about_img03.jpg" alt="About Image">
              <div class="section01_text">
                  <div class="section01_cover">
                      <img src="/img/about01.jpg" alt="About Image">
                      <h3>JUSTIN FOOD가<br>만들어가는 새로운 문화</h3>
                      <p class="mt2">단순히 음식을 드리는 것이 아닙니다.<br>전문 셰프, 푸드스타일리스트, 행사 전문 파티플래너, 공간연출 VMD가 진행하는 ONE-STOP 케이터링 서비스로 고객님의 이야기를 만들어 드립니다.</p>
                  </div>
              </div>
          </div>

          <div class="section02">
              <img src="/img/about_img04.jpg" alt="About Image">
              <div class="section02_text">
                  <div class="section02_cover">
                      <img src="/img/about02.jpg" alt="About Image">
                      <h3 class="mt1">고객님의 하루가 우리와 더<br>특별해지기를 바랍니다.</h3>
                      <p class="mt2">우리의 서비스를 찾아주신 고객님의<br>하루를 행복하게 만들 수 있도록 노력하겠습니다.</p>
                       

                    </div>
              </div>
          </div>
          <img src="/img/about_img05.jpg" alt="About Image" class="section03">
      </div>
  </div>



  <div class="about container mo1100">
      <div class="aboutPage">
          <div class="section04">
              <div class="section04_img">
                <img src="/img/about_img01.jpg" alt="About Image">
              </div>
              <div class="section04_text"  data-type="section04_01">
                      <img src="/img/about01.jpg" alt="About Image">
                      <h3 class="">JUSTIN FOOD가<br>만들어가는 새로운 문화</h3>
                      <p class="mt3">단순히 음식을 드리는 것이 아닙니다.<br>전문 셰프, 푸드스타일리스트, 행사 전문 파티플래너, 공간연출 VMD가 진행하는 ONE-STOP 케이터링 서비스로 고객님의 이야기를 만들어 드립니다.</p>
              </div>

              <div class="section04_img">
                <img src="/img/about_img06.jpg" alt="About Image">
              </div>
              <div class="section04_text" data-type="section04_02">
                      <img src="/img/about02.jpg" alt="About Image">
                      <h3 class="mt1">고객님의 하루가 우리와<br>더 특별해지기를 바랍니다.</h3>
                      <p class="mt3">우리의 서비스를 찾아주신 고객님의 하루를 <br>행복하게 만들 수 있도록 노력하겠습니다.</p>
              </div>
              <div class="section04_img">
                <img src="/img/about_img07.jpg" alt="About Image">
              </div>
          </div>
        </div>

  </div>


  
</template>


<script>
export default {
  name: 'AboutPage',

}
</script>
<style scoped>
@media (max-width: 1100px) {
  .pc1100 {
    display: none;
  }
  .mo1100 {
    display: block;
  }
}
@media (min-width: 1100px) {
  .pc1100 {
    display: block;
  }
  .mo1100 {
    display: none;
  }
}


.aboutPage{
  width: 100%;
}
.aboutPage .section01 img{
  width: 100%;
}
.aboutPage .section01 img:last-child{
  position: relative;
  top: -2px;
}
.section01{
  position: relative;
}
.section01 .section01_text{
  position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #cd7b91;
    color: #e7c3bf;
    text-align: center;
    z-index: 9;
    width: 45%;
    text-align: left;
    height: 97%;
    display: flex;
    align-items: center;
}
.section01 .section01_text img{
  max-width: 200px;
  width: 100%;
}
.section01 .section01_text p , .section01 .section01_text h3{
  max-width: 350px;
  width: 100%;
  word-break: keep-all;
}
.section01 .section01_text p{
  line-height: 1.7rem;
  font-size: 1.3rem;
}
.section01 .section01_text h3{
  line-height: 2.4rem
}
.section01_cover{
  width:100%;
  max-width: 420px;
    padding: 0 20%;
}




.section02{
  position: relative;
  top:-2px;
}
.section02 .section02_text{
  position: absolute;
    top: 50%;
    right: 0;
    background-color: #d0edee;
    color: #10312b;
    text-align: center;
    z-index: 9;
    width: 48%;
    text-align: left;
    height: 380px;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
  }
.section02 .section02_text img{
  max-width: 200px;
  width: 100%;
}

.aboutPage .section02 img{
  width: 100%;
}
.aboutPage .section02 img:last-child{
  position: relative;
  top: -4px;
}

.section02 .section02_text p , .section02 .section02_text h3{
  max-width: 350px;
  width: 100%;
  word-break: keep-all;
}
.section02 .section02_text p{
  line-height: 1.7rem;
  font-size: 1.3rem;
}
.section02 .section02_text h3{
  line-height: 2.4rem
}
.section02_cover{
  width:100%;
  max-width: 420px;
    padding: 0 10%;
}

.section03{
  width: 100%;
    position: relative;
    top: -6px;

}

/* 모바일 */
.section04 .section04_text{
  text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10% 0;
    margin-top:-2px;
}
.section04 .section04_text[data-type="section04_01"]{
  background-color: #cd7b91;
  color: #e7c3bf;
}
.section04 .section04_text[data-type="section04_02"]{
  background-color:#d0edee;
}

.section04 .section04_text h3{

  width: 100%;
  word-break: keep-all;
  line-height: 2.4rem
}
.section04 .section04_text p{
  max-width: 420px;
  width: 100%;
  word-break: keep-all;
  line-height: 2rem;
  font-size: 1.3rem;
}

.section04 .section04_img img{
  width: 100%;
}
</style>
