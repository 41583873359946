<template>
  <div class="line_guide" style="left: calc(10vw - 2px)"></div>
  <div class="Header" @mouseover="openMoMenuOver"  @mouseleave="closeMoMenuOver" :class="{ 'header': true, 'transparent': isHome, 'original_color': scrollPosition < 100, 'change_color': scrollPosition > 100, 'overHeader' : changeHeader == true}">
    <!-- / 로 가는 router-link-->
    <p id="top_logo"><router-link :to="'/'"> <div class="img"></div></router-link></p>
    <div id="menu">
      <ul class="menu-container">
        <li class="f6" v-for="category in categoriesWithChildren" :key="category.name" >
          <router-link :to="category.path" exact>{{ category.name }}</router-link>
          <ul v-if="category.children" class="submenu-container">
            <li v-for="child in category.children" :key="child.name">
              <router-link :to="child.path">{{ child.name }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
      <div class="subMenuBack" ></div>
    </div>
    <div id="moMenu">
      <i class="material-symbols-rounded f12" style="color:black" v-on:click="openMoMenu">menu</i>
      <div id="moMenuModal">
        <div class="moMenuBack" v-on:click="closeMoMenu"></div>
        <div class="moMenuContainer">
          <ul class="mo-menu-container">
            <li class="moMenu_top">
              <p class="f8" style="margin-left:2rem;">{{ phone[0] }}<br>{{ phone[1] }}</p>
              <i class="material-symbols-rounded f12" v-on:click="closeMoMenu">close</i>
            </li>
            <li class="moSubli f6" v-for="category in categories" :key="category.name">
              <template v-if="category.children && category.children.length > 0">
                <p class="f8 b9 moMenuTitle" v-on:click="MoMenuClick">{{ category.name }}
                  <i class="material-symbols-rounded f12">expand_more</i>
                </p>
                <ul class="mo-submenu-container b6 f6">
                  <li v-for="child in category.children" :key="child.name">
                    <router-link exact @click="closeMoMenu" :to="child.path">{{ child.name }}</router-link>
                  </li>
                </ul>
              </template>
              <template v-else>
                <router-link class="f8 b9 moMenuTitle" exact @click="closeMoMenu" :to="category.path">{{ category.name }}</router-link>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="line_guide" style="right: calc(10vw - 2px)"></div>
</template>
<script>
export default {
  name: 'HeaderComponent',
  props: {
    logo: {
      type: String,
      default: ''
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      scrollPosition: null,
      changeHeader: false,
      phone : ['대표전화 :', '02-718-8777'],
    }
  },
  computed: { 
    categoriesWithChildren() { // 자식 카테고리가 있는 카테고리만 필터링
      return this.categories.filter(category => { // filter() 메서드는 주어진 함수의 테스트를 통과하는 모든 요소를 모아 새로운 배열로 반환
        return category.children;// 자식 카테고리가 있는 카테고리만 필터링
      });
    },
    isHome() {
      return this.$route.path === '/';
    }
  },
  methods: {
    MoMenuClick(e) {
   
      //현재요소의 다음요소 노드를 선택 
      var submenu = e.target.nextElementSibling;
      var submenuliOne = e.target.nextElementSibling.querySelector('li');
      var submenuli = e.target.nextElementSibling.children;
      var toSubmenuH = submenuli.length * submenuliOne.offsetHeight + (submenuli.length - 1);

      //display가 none이면 block으로 변경
      if (submenu.style.height == toSubmenuH+'px') {
        submenu.style.height = 0;
      } else {
        // 모든 mo-submenu-container display를 none으로 변경
        var submenuAll= document.querySelectorAll( '.mo-submenu-container' );
        var menu= document.querySelector( '.mo-menu-container' );
        for ( var i = 0; i < submenuAll.length; i++ ) {
          submenuAll[i].style.height = 0;
        }
        submenu.style.height = toSubmenuH+'px';
        console.log(menu.style.height);
 
      }
},


  openMoMenu(){ // 메뉴가 열림
    
        // id가 moMenuModal display를 block으로 변경
        this.changeHeader = false;
      document.querySelector('#moMenuModal').style.width = '100%';
      document.querySelector('#moMenuModal').style.zIndex = 100;
      /*document.querySelector('#moMenuModal').style.display = 'block';
      document.querySelector('.moMenuBack').style.display = 'block';*/
      document.querySelector('#moMenuModal .mo-menu-container').style.transform = ' translateX(0)';
      document.querySelector('.moMenuBack').style.opacity = 1;
      //body 스크롤 막기
      document.body.style.overflow = 'hidden';

    },

    closeMoMenu(){ // 메뉴가 닫힘
  
      this.changeHeader = false;
      //document.querySelector('#moMenuModal').style.display = 'none';
      document.querySelector('.moMenuBack').style.opacity = 0;
     // document.querySelector('.moMenuBack').style.display = 'none';
      document.querySelector('#moMenuModal .mo-menu-container').style.transform = ' translateX(100%)';
      document.querySelector('#moMenuModal').style.zIndex = -1;
      setTimeout(()=>{document.querySelector('#moMenuModal').style.width = 0}, 300);
    document.body.style.overflow = 'auto';
    },

    openMoMenuOver() {
      if(window.innerWidth >= 990){
        this.changeHeader = true;
      }
      document.querySelector('.subMenuBack').style.height = '340px';
      var submenu= document.querySelectorAll( '.submenu-container' );
      for ( var i = 0; i < submenu.length; i++ ) {
        submenu[i].style.opacity=1;
        submenu[i].style.fontSize='inherit';
      }
    },
    closeMoMenuOver() {
      this.changeHeader = false;
      document.querySelector('.subMenuBack').style.height = '0';
      var submenu= document.querySelectorAll( '.submenu-container' );
      for ( var i = 0; i < submenu.length; i++ ) {
        submenu[i].style.opacity=0;
        submenu[i].style.fontSize=0;
      }
    },
    updateScroll(){
      this.scrollPosition = window.scrollY
    }
  },
  mounted(){
    window.addEventListener('scroll', this.updateScroll);

   
  }
  ,created(){

  },


}
</script>
<style scoped>
.moMenuTitle{

  padding: 10px 0;
  cursor: pointer;
}
.moMenuTitle i{z-index: -1;}
.moMenu_top i{
  margin-right: 2rem;
}
.moSubli{

  position: relative;
  overflow: hidden;
}
</style>