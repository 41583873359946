<template>
    <div class="Contact container">
      <div class="topBanner container">
           <h1 data-aos="fade-up" data-aos-duration="1500">{{ title }}</h1>

       </div>
      <div id="contactBox" class="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
        <div class="leftContactBox">
     
          <h3 class="f7">{{ leftContactBoxH3[0] }}</h3>
          <p>{{ leftContactBoxP[0] }}</p>
          <h3 class="f7">{{ leftContactBoxH3[1] }}</h3>
          <p>{{ leftContactBoxP[1] }}</p>
          <h3 class="f7">{{ leftContactBoxH3[2] }}</h3>
          <p>{{ leftContactBoxP[2] }}</p>

          <address class="f6">
            <p>{{ContactAddress}}</p>
            <p>{{ ContactPhone }}</p>
            <p>{{ ContactEmail }}</p>
          </address>

        

        </div>
        <div class="rightContactBox"> 
          <div class="form-group">
            <p>(주) 저스틴푸드는 귀하의 개인정보보호를 매우 중요시하며, 개인정보보호방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</p>

            <p>◆수집항목</p>
            <p>- 회사명, 담당자성명 : 상담 신청등의 서비스 이용에 따른 본인 확인 절차에 이용</p>
            <p>- 이메일주소, 전화번호 : 상담 신청 처리 등을 위한 원활한 의사소통 경로의 확보</p>
            <p>- 기타 선택항목 : 웹 컨설팅을 위한 기초 자료 확보</p>

          </div>
          <div class="memberInfo">
            <p class="f2 b6">{{ ContactMemberInfo }}</p>
            <label for="agree" class="f2 b6">{{ ContactAgree }}
              <input type="checkbox" name="agree" id="agree">
            </label>
          </div>
          

          <form class="inputBox" action="https://justincatering.com/mail/justin.php" method="POST" @submit.prevent="onSubmit" id="contactForm">
            <input type="hidden" name="site" value="justinfood">
            <input type="text" placeholder="회사명" name="company">
            <input type="text" placeholder="담당자 성명" name="name">
            <input type="phone" placeholder="연락처" name="phone">
            <input type="email" placeholder="이메일" name="email">
            <textarea class="b4"  id="" cols="30" rows="10" placeholder="내용" name="content">

            </textarea>
            <button :v-on:click="onSubmit"  class="download f6" type="submit" >문의하기</button>
          </form>  

        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: 'ContactComponent',
    data() {
      return {
        title:"문의하기",

        publicPath: process.env.BASE_URL + "public/introduction.pdf",

        leftContactBoxH3:['사업 문의','제휴 문의','채용 문의'],
        leftContactBoxP:['양식에 따라 문의 주시면 관련 담당자가 유선으로 연락해드립니다.','제휴를 원하시는 기업은 제휴 내용을 작성하셔서 보내주시면 검토 후 연락드리겠습니다.','PDF 또는 PPT 형식의 포트폴리오를 아래 이메일로 보내주시면 면접자에 한하여 개별 연락드리겠습니다.'],
        ContactAddress:'주소 : 서울특별시 마포구 월드컵북로 128, 1층',
        ContactPhone:'전화 : 02-718-8777',
        ContactEmail:'이메일 : manager@justinfood.co.kr',
        ContactMemberInfo:'위의 개인정보 취급방침을 읽어보시고 동의 여부를 결정하시기 바랍니다.',
        ContactAgree:'동의함',
        ContactSend:'SEND'
      }
    },
    methods: {
      onSubmit(event) {
    event.preventDefault();
    const companyName = document.querySelector('input[placeholder="회사명"]').value;
    const phone = document.querySelector('input[placeholder="연락처"]').value;
    const email = document.querySelector('input[placeholder="이메일"]').value;
    const agree = document.querySelector('#agree').checked;

    if (!companyName || !phone || !email ) {
      alert('모든 필수 입력란을 채우시고 동의에 체크해주세요.');
      return false;
    }
    else if (!agree) {
      alert('개인정보 취급방침에 동의해주세요.');
      return false;
    }
    else {
      // submit
      document.querySelector('#contactForm').submit();
    
      return false; 
    }
  }
},
    mounted() {
      document.querySelector('#contactForm').addEventListener('submit', this.onSubmit);
    },
    beforeUnmount() {
      document.querySelector('#contactForm').removeEventListener('submit', this.onSubmit);
    }
  }
</script>
  
<style>
/*#contactBox*/
.rightContactBox .form-group{
  border:1px solid #ddd;
  border-radius: 1rem;
  padding:2rem;
  margin-bottom:4rem;
}
.rightContactBox .form-group p:first-child{
  font-weight: 600;
  margin-bottom: 2rem;
}
.rightContactBox .form-group p:not(:first-child){
  margin-bottom: 0.3rem;
}
#contactBox{
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
#contactBox .leftContactBox {
  width: 40%;
  word-break: keep-all;
}
#contactBox .leftContactBox h2 {
  font-weight: 800;
  margin-bottom: 6vh;
}
#contactBox .leftContactBox h3 {
  font-weight: 800;
  margin-bottom: 1vh;
}
#contactBox .leftContactBox p {
  margin-bottom: 3vh;
  font-weight: 500;;
}
#contactBox .leftContactBox address p{
  margin-bottom:0.7vh;
  font-weight: 800;
}

.download{
  width: 18rem;
    aspect-ratio: 1/0.2;
    display: flex;
    border-radius: 1rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    margin-top: 6vh;
    font-weight: 500;
    background: #fff;
    border: 0;
    transition: 0.3s;
    align-items: center;
    justify-content: center;
    color: #000;
  
   
 
}
.download:hover{
  background: #ffcfcf;
}
.download a{
  display: block;
  padding: 1rem;
  color: inherit;
}

#contactBox .rightContactBox {
  width: 50%;

}

#contactBox .rightContactBox textarea {
  width: calc(100% - 2rem - 2px);
  height: 25vh;
  margin-bottom: 2vh;
  padding: 1rem;

  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  resize: none;
  text-align: left;
  border:1px solid #e3e3e3;
}
.memberInfo{
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#agree{
  margin: 0;
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
.rightContactBox label{
 display: flex;
 align-items: center;
}
.inputBox{
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
margin-top:1.2rem;
}
#contactBox .rightContactBox .inputBox input{
  width: 46%;
  border-radius: 0.5rem;
  padding: 0.8rem 0.5rem;
  margin-bottom:1rem;
  border:1px solid #e3e3e3;
  font-weight: 400;
}
#contactBox .rightContactBox  .inputBox textarea{
  border-radius: 0.5rem;
  border:1px solid #e3e3e3;
  padding: 0.8rem 0.5rem;
  width: 100%;
  font-weight: 400;
}
          
.inputBox button{width: 25rem;margin-right: auto; position: relative; left: -12rem; font-weight:900; cursor:pointer;}
@media screen and (max-width: 990px) {
  .inputBox button{
    margin:0 auto;
    left: 0;
  }
  .download{
    margin-top: 4vh;
    cursor: pointer;
  }
  #contactBox {
 
    flex-direction: column;
}
#contactBox .leftContactBox {
  width: 100%;
 margin-bottom: 5vh;
}
#contactBox .rightContactBox {
  width: 100%;
}

}
@media screen and (max-width: 290px) {
  #contactBox .rightContactBox .inputBox input{
    width: 100%;
  }
}
</style>