<template>
  <div class="FamilyChannel">

    <div class="container">

      
      <div class="contents">
        

        <ul class="container" style="display: flex; margin-top: 5vh; flex-wrap: wrap; justify-content: flex-start;">
          <li v-for="(item, index) in companyInfo" :key="index" class="Channelbox">
              <div class="ChannelList" data-aos="fade-up" data-aos-duration="1000" :data-aos-delay="index+'00'">
                  <p class="ChannelList_logo"><img :src="this.siteLogo[index]" alt=""></p>
               
                      
                      <div class="ChannelList_sns">
                        <p class="center ChannelList_title b6">{{ item.type }}</p>
                        <p v-for="(it, i) in item.sns" :key="i">
                          <a :href="item.sns[i]" target="_blank"><img :src="this.sns[i]" /></a>
                        </p>
                      </div>
                  
             
              </div>
          </li>

            
        </ul>
      </div>
    </div>
  
  </div>
</template>

<script>


export default {
  name: 'FamilyChannelComponent',

  props:{
    siteLogo: Array,
    companyInfo:Array,
    sns:Array
   
  },
  data(){
    return {
      title:'MEDIA HUB',
      subTitle:'저스틴푸드의 다양한 브랜드 소식과 콘텐츠들을 한눈에 확인해보세요!',
      contentsTitle:'패밀리 공식채널',
      contentsText:'각 브랜드들의 공식 채널을 한눈에 확인해 보세요'

      
    }
  }

}
</script>
<style scoped>


.center{
  text-align: center;
}


/* 맨마지막 제외 */

.FamilyChannel .Channelbox:first-child, .FamilyChannel .Channelbox:last-child{
margin-left: 7%;
}
.FamilyChannel .Channelbox{
width:26%;
margin-top:10vh;
margin-left: 4%;

}
.FamilyChannel .Channelbox .ChannelList{
  aspect-ratio: 1 / 0.5;
  display: flex;
    align-items: center;
}
/* 3개이후 전부*/
.FamilyChannel .Channelbox:nth-child(n+4){
  margin-top: 14vh;
}
.FamilyChannel .ChannelList{
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 2rem;
  position: relative;

}


.FamilyChannel .ChannelList_logo{
  border-radius: 100%;
  width: 10vh;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  position: absolute;
  top:-50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
background-color: white;
}

.FamilyChannel .ChannelList_logo img{
  width: 100%;
  height: auto;
}

.ChannelList_sns{
  display: flex;
    width: 60%;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: center;
    padding: 3.5vh 0;
    flex-wrap: wrap;
}
.ChannelList_sns img{
  width: 100%;
  height: auto;
}

.ChannelList_sns .ChannelList_title{
  padding: 13% 0 6% 0;
}
.ChannelList_sns p:first-child{
  width: 100%;
}
.ChannelList_sns p:nth-child(n+2){
  width: 25%;
    aspect-ratio: 1/1;
  /*그림자*/
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 100%;
  text-align: center;
 
  display: flex;
    justify-content: center;
    align-items: center;
    
}
.ChannelList_sns p:nth-child(n+2):hover{
  background-color: #ffcfcf;
}

.ChannelList_sns p a{
  width: 40%; 
}
.ChannelList_sns p img{
  width:100%;
}



@media screen and (max-width: 990px) {
  .FamilyChannel .Channelbox{
    width: 40%;
    
  }

  .FamilyChannel .Channelbox:first-child{
    margin-left: 7%;
  }
  .FamilyChannel .Channelbox:nth-child(3){
    margin-left: 7%;
  }
 .FamilyChannel .Channelbox:last-child{
margin-left: 4%;
margin-top: 10vh;

}


}
@media screen and (max-width:480px){
  .FamilyChannel .Channelbox{
    width: 90%;
    
  }
  #app .FamilyChannel .Channelbox{
    margin-left: 5%;
  }

}
</style>
