<template>
  <div class="black-bg" v-if="modalBool == true">
    <div class="white-bg">
      <div class="modalBtnGroup">
        <img :src="modalBtnImg[0]" @click="modalPrev">
        <img :src="modalBtnImg[1]" @click="modalClose">
        <img :src="modalBtnImg[2]" @click="modalNext">
      </div>
      <div class="historyContent">
        <div class="historymodalLeft">
          <p class="f8 b6">{{ historyModal[clickNum].date }}</p>
          <p class="f9 b8">{{ historyModal[clickNum].text }}</p>
          <span></span>
          <p class="f5 b4">{{ historyModal[clickNum].subText }}</p>
        </div>
        <div class="historymodalRight" :style="{ backgroundImage: 'url('+ historyModal[clickNum].img +')' }"></div>
      </div>
    </div>
  </div>

  <div class="History">
    <div class="topBanner container">
      <h1>{{ title }}</h1>
      <h2 class="f7">{{ subTitle }}</h2>
    </div>
    <div class="historyContant container" v-for="(historyDate, index) in historyDate" :key="index" :style="{ backgroundImage: 'url('+ historyImg[index] +')' }">
      <div class="historyGroup">
        <div class="historyY f14 b6">{{ historyDate }}</div>
        <div class="historyYleft">
          <div class="historyYcontant"  v-for="(historyImg, i) in history[historyDate]['img']" :key="i">
            <div class="historyYGroup" :style="{ backgroundImage: 'url('+ historyImg +')' }">
              <div class="historyViewMore f9 b6" @click="modalBool = true; clickNum = history[historyDate]['idx'][i]">MORE VEIW</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HistoryComponent',
  data(){
    return{
      title:"회사연혁",
      subTitle:"건강한 행복, 건강한 외식문화의 No.1 그룹이 되겠습니다.",
      clickNum : 0,
      modalBtnImg:[require('/public/img/historyPrev.png'),require('/public/img/historyClose.png'),require('/public/img/historyNext.png')],
      modalBool : false,
      historyDate:[2015, 2018, 2020, 2022],
      historyImg:[require('/public/img/historyY1.jpg'),require('/public/img/historyY2.jpg'),require('/public/img/historyY4.jpg'),require('/public/img/historyY3.jpg')],
      historyModal:{
        0:{
          date:'2015. 06',
          text:"한우 스테이크 하우스 '저스틴스테이크' 오픈",
          subText:"최상급 등급의 한우 스테이크를 합리적인 가격으로 만나보세요.",
          img:require('/public/img/history2015_1.jpg'),
        },
        1:{
          date:'2018. 02',
          text:"프리미엄 케이터링 '저스틴 케이터링' 브랜드 런칭",
          subText:"전문 쉐프 및 푸드 스타일리스트, 플로리스트, 공간연출가, 촬영팀으로 구성된 케이터링 전문가가 함께합니다.",
          img:require('/public/img/history2018_1.jpg'),
        },
        2:{
          date:'2018. 11',
          text:"(주)저스틴푸드 법인 설립 및 사업 확장",
          subText:"전혀 다른 프리미엄 식사 대접 서비스, 저스틴푸드!",
          img:require('/public/img/history2018_1.jpg'),
        },
        3:{
          date:'2020. 01',
          text:"2020년 1월 무빙퀴진 '더셀렉션' 브랜드 런칭 ",
          subText:"하나부터 열까지 커스터마이징이 가능한 맞춤형 도시락 서비스!",
          img:require('/public/img/history2018_1.jpg'),
        },
        4:{
          date:'2022. 12',
          text:"저스틴스테이크 '더셀렉션 미트' 브랜드 런칭",
          subText:"이제, 저스틴스테이크만의 숙성 비법으로 풍미와 질감을 업그레이드한 명품 한우 스테이크를 집에서도 즐기실 수 있습니다.",
          img:require('/public/img/history2018_1.jpg'),
        },
      },
      history:{
        2022: {
          idx:[4],
          img:[require('/public/img/history2022_1.jpg'),]
        },
        2020: {
          idx:[3],
          img:[require('/public/img/history2022_1.jpg'),]
        },
        2018: {
          idx:[1,2],
          img:[require('/public/img/history2018_1.jpg'),require('/public/img/history2018_2.jpg'),]
        },
        2015: {
          idx:[0],
          img:[require('/public/img/history2015_1.jpg'),]
        },
      }
    }
  },
  methods:{
    modalPrev(){
      if(this.clickNum>0){
        this.clickNum--;
      }
    },
    modalClose(){
      this.modalBool = false;
    },
    modalNext(){
      if(this.clickNum<4){
        this.clickNum++;
      }
    }
  }
}
</script>

<style>
  .historyTit{font-weight: 900; color:#ffaeae; margin-bottom:30px;}
  .historyTextgroup{display:flex; margin: 30px 0;}
  .historyContant{background-repeat: no-repeat; background-size: contain; background-position: center; padding-bottom: 15%;}
  .historyGroup{max-width:60.32vw; width:100%; margin:0 auto; display:flex; justify-content: space-between; }
  .historyYcontant{margin-bottom:7%;}
  .historyYGroup{position: relative; display: inline-block; background-size: cover; background-repeat: no-repeat;}
  .historyViewMore{
    width:100%; 
    height: 100%;
    background:rgba(0,0,0,0.7); 
    color:#fff; 
    opacity: 0;
    display: flex;
    justify-content: center; 
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
  }
  .historyYGroup:hover .historyViewMore{opacity: 1;}
  .historyYcontant:nth-child(3n - 2) .historyYGroup{width: 42.8vw; aspect-ratio: 1/0.65;}
  .historyYcontant:nth-child(3n - 1) .historyYGroup{left:-24%; width: 21.72vw; aspect-ratio: 1/1.365;}
  .historyYcontant:nth-child(3n) .historyYGroup{left:17%;  width: 36.9vw; aspect-ratio: 1/0.8;}

  .black-bg {
  width: 100%; height:100%;
  background: rgba(0,0,0,0.5);
  position: fixed; padding: 20px;
  z-index: 101;
  top:0;
  margin-top:0 !important;
}
.white-bg {
  width: calc(60.32vw - 40px); 
  background: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:#fff;
  padding: 20px;
} 
.modalBtnGroup{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalBtnGroup img{cursor: pointer;}
.historyContent{display:flex; justify-content: space-between; width:77.2%; margin: 11% auto 7%;}
.historymodalRight{width:19.3vw; aspect-ratio: 1/1.335; background-position: center; background-repeat: no-repeat;}
.historymodalLeft{width:20.3vw; word-break: keep-all;}
.historymodalLeft p:nth-child(1){color:#ffbcbc;}
.historymodalLeft p:nth-child(2){margin:17% 0; line-height: 1.2;}
.historymodalLeft span{display: inline-block; height:1px; width:10%; background:#ffbcbc; margin-bottom:17%;} 
.historymodalLeft p:last-child{line-height: 1.2; width:17.3vw;}
@media screen and (max-width:990px) {
  .historyContant:last-child{padding-bottom: 0;}
  .historyContant {background-image: none !important;}
  .historyGroup{max-width: 100%; flex-direction: column;}
  .historyY{margin-bottom: 3%;}
  .historyYcontant:nth-child(3n - 2) .historyYGroup, .historyYcontant:nth-child(3n - 1) .historyYGroup, .historyYcontant:nth-child(3n) .historyYGroup{
    width:100%; 
    aspect-ratio: 1/0.65; 
    left:0;
  }
  .black-bg{
    padding:0;
    top:7rem;
  }
  .white-bg{
    width:calc(100% - 40px); 
    height:100%; 
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
  .historyContent{width:100%; flex-direction: column-reverse;}
  .historymodalRight{ width:100%; aspect-ratio: 1/0.65; background-size: 100%;}
  .historymodalLeft, .historymodalLeft p:last-child{width:100%;}
  .historymodalLeft{margin: 20% 0;}
  .historymodalLeft p:nth-child(2){margin: 10% 0;}
  .historymodalLeft span{margin-bottom: 10%;}

}
@media screen and (max-width:450px) {
  .modalBtnGroup img{width:35px;}
}
</style>
