<template>
  <div class="main">

<div class="video-container">
    <video
      class="responsive-video"
      autoplay
      muted
      loop
      playsinline
    >
      <source src="/videos/banner.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>


      <div class="mainContainer">
          <div class="mainGroup">
              <div class="mainNewsFlex">
                  <div class="mainTitGroup" data-aos="fade-up" data-aos-duration="1500">
                      <p class="mainTit f11">{{ mainBrandTit }}</p>
                
                  </div>
                  <div class="mainFoodHash">
                    <p
                      v-for="(tag, index) in mainBrandHasyTag"
                      :key="index"
                      :data-aos-delay="200 + index * 100"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      class="hashTag f7"
                    >
                      <span>{{ mainBrandHasy }}</span>{{ tag }}
                    </p>
                  </div>
              </div>
              <div class="mainContent" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
                <div
                  class="mainBrand"
                  v-for="(logo, index) in currentSiteLogos"
                  :key="index"
                  @click="onClickRedirect(companylink[index])"
                  @mouseover="logoHoverIn"
                  @mouseout="logoHoverOut"
                >
                    <div>
                      <img :src="logo" />
                      <div class="hoverTextGroup">
                        <p class="f6">{{ companyName[index] }}</p>
                        <div class="lineBar"></div>
                        <div class="f4" v-html="companyInfo[index].info"></div>
                      </div>
                    </div>
                </div>

              </div>
              <div data-aos="fade-up" data-aos-duration="1500" class="viewBtn f6 brandView">
                  <router-link to="brand">{{ viewAll }}</router-link>
              </div>
          </div>
      </div>

       <div class="mainGroup" data-aos="fade-up" data-aos-duration="1500">

          <div class="mainTitGroup">
            <p class="mainTit f11">{{ partnerLogoTit }}</p>
           
          </div>

          <div class="mainBrandLogo horizontal-list">
            <div v-for="slide in partnerLogo" :key="slide" class="logo-item">
              <img :src="'https://justincatering.com' + slide.title_img.substr(5)" alt="Partner Logo">
            </div>
          </div>

        </div>
        <div data-aos="fade-up" data-aos-duration="1500" class="viewBtn f6">
                  <router-link to="partners">{{ viewAll }}</router-link>
         </div>

      </div>


</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import axios from 'axios'

export default {
  name: 'MainComponent',
  props: {
    siteLogo2: Array,
    siteLogo: Array,
    companyInfo: Array,
  },
  data() {
    return {
      img: null,
      companyName: ['저스틴케이터링', '저스틴스테이크 기프트', '저스틴푸드마켓'],
      companylink: ['https://justincatering.com', 'http://smartstore.naver.com/justinsteak', 'https://smartstore.naver.com/justinfoodmarket'],
      mainBannerSubTit: 'Happy with JUSTIN FOOD',
      mainBannerTit: ['더 나은, 더 행복한  외식문화', 'JUSTIN FOOD 와 함께하세요.'],
      mainBrandTit: 'JUSTIN BRAND',
      mainBrandHasy: '#',
      mainBrandHasyTag: ['외식','케이터링','프리미엄 한우세트'],
      mainNewsTit: 'JUSTIN NEWS',
      mainNewsSubTit: '저스틴푸드의 다양한 최신뉴스를 확인해보세요',
      mainMagazineTit: 'JUSTIN MAGAZINE',
      mainMagazineSubTit: '저스틴푸드 매거진 뉴스레터로 새소식을 접해보세요',
      MagazineTit: 'JUSTIN FOOD',
      MagazineSubTit: 'MAGAZINE',
      viewAll: 'VIEW ALL',
      viewMore: 'VIEW MORE',
      partnerLogoTit: 'JUSTIN PARTNERS',
      newsPosts: [],
      magazine: [],
      partnerLogo: [],
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      breakpoints: {
        990: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      breakpoints1: {
        300: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        990: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
        1440: {
          itemsToShow: 7,
          snapAlign: 'start',
        }
      },
    }
  },
  computed: {
    currentSiteLogos() {
      return window.innerWidth <= 990 ? this.siteLogo : this.siteLogo2;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    axios.post('https://justincatering.com/api/justinfood.php', { path: 'main', value: 'news' })
      .then(response => {
        console.log('newsPosts response:', response.data);
        this.newsPosts = response.data;
      })
      .catch(error => {
        console.error('Error:', error);
      });

    axios.post('https://justincatering.com/api/justinfood.php', { path: 'main', value: 'news_post' })
      .then(response => {
        console.log('magazine response:', response.data);
        this.magazine = response.data;
      })
      .catch(error => {
        console.error('Error:', error);
      });

    axios.post('https://justincatering.com/api/justinfood.php', { path: 'main', value: 'logo' })
      .then(response => {
        console.log('partnerLogo response:', response.data);
        this.partnerLogo = response.data;
      })
      .catch(error => {
        console.error('Error:', error);
      });

    if (window.innerWidth <= 990) {
      // 990 이하면 모바일 메인 배너 노출
      this.img = [require('/public/img/momainbanner0.jpg'), require('/public/img/momainbanner1.jpg'), require('/public/img/momainbanner2.jpg')]
    } else {
      // 990 초과면 pc 메인 배너 노출
      this.img = [require('/public/img/pcmainbanner0.jpg'), require('/public/img/pcmainbanner1.jpg'), require('/public/img/pcmainbanner2.jpg')]
    }
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
  methods: {
    handleResize() {
      this.$forceUpdate();
    },
    logoHoverIn(e) {
      e.stopPropagation();
      e.currentTarget.querySelector('.hoverTextGroup').style.opacity = 1;
    },
    logoHoverOut(e) {
      e.stopPropagation();
      e.currentTarget.querySelector('.hoverTextGroup').style.opacity = 0;
    },

      onClickRedirect(index) {
      const link = index;
      if (link) {
        // 링크 새창으로 열기
        window.open(link);
      }
    },
    mouseOverNews: function (e) {
      //속도
      e.currentTarget.querySelector('.newBackgroundImg').style.transition = 'all 0.5s ease-in-out';
      //크기 1.1배
      e.currentTarget.querySelector('.newBackgroundImg').style.transform = 'scale(1.1)';

      e.currentTarget.querySelector('.newsSView').style.transition = 'all 0.3s ease-in-out';
      e.currentTarget.querySelector('.newsSView').style.backgroundColor = 'rgba(0,0,0,0.5)';
      e.currentTarget.querySelector('.newsSView').style.backgroundColor = '#ffcfcf';
      e.currentTarget.querySelector('.newsSView').style.color = 'rgba(255,255,255,1)';
    },
    mouseLeaveNews: function (e) {
      e.currentTarget.querySelector('.newBackgroundImg').style.transform = 'scale(1)';
      e.currentTarget.querySelector('.newsSView').style.backgroundColor = '#f4f4f4';
      e.currentTarget.querySelector('.newsSView').style.color = 'rgba(0,0,0,1)';
    },
  }
}
</script>


<style scoped>
.video-container {
  width: 100%;
  height: 100vh; /* Viewport height, fills the screen */
  overflow: hidden; /* Hide overflow for better responsiveness */
  position: relative;
}

.responsive-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the container */
  position: absolute;
  top: 0;
  left: 0;
}

.main{margin-top:0 !important;}
.mainBanner{position: relative; overflow: hidden;}
.mainBanner .carousel__slide{padding:0 !important;}
.mainBanner .carousel__item {
  width: 100%;
  color: var(--vc-clr-white);
}
.mainBanner .carousel__item img{width:100%; margin: 0;}
.mainBanner .carousel__slide {padding: 10px;}
.mainBanner .carousel__prev,
.mainBanner .carousel__next {box-sizing: content-box;}
.mainBanner .carousel__pagination{position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%);}
.mainBanner .carousel__pagination-button{padding: 9px;}
.mainBanner .carousel__pagination-button::after{width: 20px; height: 20px; border-radius: 50%; background-color:rgba(255,255,255,0.7);}
.mainBanner .carousel__pagination-button:hover::after, .mainBanner .carousel__pagination-button--active::after{background-color: #ffcfcf;}
.mainBanner .NavCon{position:absolute; max-width:var(--width-container); width:100%; top:50%; left: 50%; transform: translate(-50%, -50%); z-index: 2;}
.mainBanner .carousel__icon{fill: white; transform: scale(1.8);}
.mainBannerTitGroup{position: absolute; top: 48%; left: 50%; transform: translate(-50%, -50%); width:60vw;}
.mainBannerTitGroup p{color:#fff;}
.mainBannerTitGroup .mainBannerTit{font-weight:600; line-height: 1.2;}
.mainBannerSubTit{line-height:1.3;}


.mainNewsNav{
  position:absolute;
  top: -110px;
    right: 0;
    width: 12rem;
}
.mainNewsNav .carousel__prev, .mainNewsNav .carousel__next{height:63px; width: 63px; border-radius: 50%; border: none; box-shadow: 0 0 29px rgb(0 0 0 / 7%);}
.mainNewsNav .carousel__prev{background:#fff url("/public/img/prev.png") no-repeat center; transition: 0.3s;}
.mainNewsNav .carousel__next{background:#fff url("/public/img/next.png") no-repeat center; transition: 0.3s;}
.mainNewsNav .carousel__prev:hover{background-color: #ffcfcf;}
.mainNewsNav .carousel__next:hover{background-color: #ffcfcf;}
.mainNewsNav .carousel__icon{display: none;}
.mainNewsGroup{width:100%;}
.mainNewsCon .carousel__item {
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 10px rgb(0 0 0 / 7%);
}
.mainNewsCon .carousel__item .newBackgroundImg{
  background-size: cover;
  background-position: center;
  aspect-ratio: 1 / 0.7;
  width: 100%;

}

.mainNewsCon .carousel__slide {
  padding: 10px 30px;
}

.newsView{margin-bottom:var(--main-bottom-margin);}

.mainContainer{padding:8rem 0;}
.mainContainer:nth-child(3){background:#fffaf4;}

.mainGroup{
  max-width: var(--width-container);
  
   width: 100%; margin: 0 auto;
  margin-bottom: var(--main-bottom-margin);
  }

.mainTitGroup{margin-bottom:var(--main-bottom-margin);}
.mainTitGroup .mainTit{font-weight:900; margin-bottom:15px;}
.mainTitGroup p{font-weight:600;}
.mainContent{
  width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--main-bottom-margin);
    justify-content: center;
}
.mainBrand{
  width:18%; 
  cursor: pointer; 
  box-sizing: border-box;
  margin-right: 4%;
}
.mainBrand:last-child{margin-right:0;}


.mainBrand>div{
  border-radius: 50%; 
  aspect-ratio: 1/1; 
  box-shadow: 0 0 29px rgba(0,0,0,0.08); 
  display: flex; 
  justify-content: center; align-items: center; transition:0.5s; position:relative;
}
.mainBrand:hover>div{border-radius: 30px;}
.mainBrand img{width:100%; transition: 0.5s; max-width: 180px;}

.hoverTextGroup{position: absolute;font-weight:500; padding:0 6%; text-align:center; word-break: keep-all; opacity: 0; transition: 0.3s; margin-top:10%;}
.hoverTextGroup .lineBar{height:1px; width:10%; margin:6% auto; background: #000;}
.viewBtn{
  background:#fff; 
  border-radius: 50px; 
  box-shadow: 0 0 29px rgba(0,0,0,0.05); transition: 0.3s; 
   font-weight: bold; cursor:pointer;
   width: 18rem;
  aspect-ratio: 1/0.25; 
  margin: 0 auto;
}
.viewBtn a{
  display: block; 
  width: 100%;
  height: 100%;
  

  display: flex; justify-content: center; align-items: center;
}
.brandView{margin:0 auto;}
.mainNewsFlex{
  display: flex; justify-content: space-between; align-items: center;
  flex-wrap: wrap;
}

.newsTextGroup{padding:7% 5% 5%; background: #fff; position:relative; z-index: 9;}
.newsTit{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  padding-bottom:20px;
  color:#000;
  font-weight: bold;
  text-align: left;
}
.newsTextDate{display: flex; justify-content: space-between; align-items: center; color:#000;}
.newsDate{color:#898989;}
.newsSView{
  width: 12rem;
  padding: 20px 15px; background: #f4f4f4; border-radius: 40px; font-weight:500; cursor:pointer; transition: 0.3s;
}
.newsSView:hover, .viewBtn:hover{background:#ffcfcf;}
.mainMagazine{display:flex; justify-content: space-between; align-items: center; width:100%;}
#app .mainMagazineImg{
  background:gray; 
  width:23%;
  aspect-ratio: 1/0.9; 
  text-align: center; 
  overflow: hidden; 
  position: relative;
  opacity: 0.6;
  transition: 0.3s;
}
#app .mainMagazineImg:hover{opacity: 1;}
.mainMagazineImg img{position:absolute; top:50%; left:50%; transform: translate(-50%, -50%); }
.mainMagazineTit{width:100%; position: absolute; top:50%; transform: translateY(-50%); background:rgba(255,255,255,0.7); padding: 20px 0;}
.mainMagazineTit p{line-height:1.4;}
.magazineTit{font-weight:800; color:#000;}
.magazineSubTit{letter-spacing: 2px; font-weight:600; color:#000;}
.magazinedate {letter-spacing:2px; font-weight:600; color:#605e5e;}

.mainBrandLogo, .mainBrandLogo img{width:100%;}

.mainBrandLogo .carousel__item {
  width: 100%;
  background:#fff;
  color: #000;
  font-size: 20px;
  overflow: hidden;
  border: 1px solid #eee;
}

.mainFoodHash{
  display:flex; 
  margin-bottom:var(--main-bottom-margin);
  flex-wrap: wrap;
}
.hashTag{margin-left:4rem; font-weight: 600; padding: 5px 10px; }
.hashTag span{background: #ffcfcf; border-radius: 50%; margin-right: 5px; width:30px; height:30px; text-align: center; line-height: 30px; display: inline-block;}

.mainBrandLogo.horizontal-list {
  display: flex;

    align-items: center;
    padding: 10px 0;
    flex-wrap: wrap;
}

.logo-item {
width: 12.5%;
margin-bottom:2%;
}

.logo-item img {
  max-height: 100px; /* Set a max height for logos */
  max-width: 150px; /* Set a max width for logos */
  object-fit: contain; /* Ensure logos maintain aspect ratio */
}
@media screen and (max-width: 1400px){
  .logo-item {
    width: 16.66%;
  }
}

@media screen and (min-width: 990px){


  .mainBrand img{        transform: translateY(25%);}
  .mainBrand:hover img{width:70%; transform: translateY(-45%);}



}
@media screen and (max-width: 990px){
  .logo-item {
    width: 25%;
  }

.mainContent {
    flex-direction: column;
  }
.mainBrand{
    margin-right: 0;
    margin-bottom: 4rem;
}
.mainBrand:last-child{
    margin-bottom: 0;
}
#app .mainBrand{
  width: 40%;
}
  
#app .mainMagazineImg{
opacity: 1;
}
#app .mainMagazineImg:hover{
opacity: 0.7;
}
  .newsSView{
    width: 20rem;
  }
  .hoverTextGroup{

    display: none;

  }
  .mainBrand > div{
  /* 호버 막기 */
  pointer-events: none;
  }

  
 /* .mainBrand:hover{
    pointer-events: none;
  }*/
  #app .mainNewsCon .mainContent{
    margin-bottom: 0 !important;
  }
 
 
  #app .mainMagazine .mainMagazineImg{
    width:30%;
  }
  #app .mainMagazine .mainMagazineImg:last-child{
   display:none;
  }
  #app .mainMagazine .mainMagazineImg .mainMagazineTit{
    padding:10px 0;
  }
  #app .mainBrandLogo .carousel__item{
    width: 60%;
  }


  
  #app .mainNewsNav{

    top: 40%;
    left: 0 !important;
    right: inherit;
    width: 100%;
    transform: translateY(-50%);
  }
  #app.viewBtn{

    width: 30vw;
    aspect-ratio: 1 / 0.25;
  
  }
}
@media screen and (max-width: 680px){
  .logo-item {
    width: 50%;
    text-align: center;
  }

  .carousel__prev, .carousel__next{
    margin: 0;
  }

  #app .mainContainer{  padding:5rem 0;}
.mainContent{
  margin-top: 4rem !important;
}


#app .mainNewsFlex .viewBtn{
  display: none;
}



  #app .mainBannerTitGroup{width:90%; text-align: center;}
}
@media screen and (max-width: 480px){
  .mainBrandLogo.horizontal-list {
    justify-content: space-between;
  }
  .logo-item {
    width: 49%;
  }
  .logo-item img{
    max-height: unset;
    max-width: 100%;
  }

  
  .newsSView{
    width: 15rem;
  }
  
.mainBannerTit span{
  font-size: 2.4rem; line-height: 1rem;
}
  #app .mainMagazine .mainMagazineImg{
    width:100%;
  }
  #app .mainMagazine .mainMagazineImg:nth-child(2),#app .mainMagazine .mainMagazineImg:nth-child(3){
   display:none;
  }

  #app .mainBrand{
  width: 60%;
}
 
}

@media (prefers-color-scheme: dark){
  .mainBrand>div{
    border: 1px solid #fff;
  }

}


</style>

