<template>
  <div class="footer">
    <div class="footLogo">
      <img :src="logo">
    </div>
    <div class="footContent">
      <div class="footContentLeft">
        <ul>
          <li>
            <router-link to="/privacy-policy">{{ footContentLeftLi[0] }}</router-link>
          </li>
          <li>
            <router-link to="/marketing-policy">{{ footContentLeftLi[1] }}</router-link>
          </li>
        </ul>
        <p>{{ footAddress }}</p>
        <p>{{ footCopyright }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterComponent',
  data(){
    return{
      arrow:require('/public/img/arrowTop.png'),
      show: false,
      footContentLeftLi:['개인정보처리방침','마케팅활용방침'],
      footAddress:'서울특별시 마포구 월드컵북로 128, 1층',
      footCopyright:'Copyright 저스틴푸드 All rights reserverd.',
  
    }
  },
  props: {
    logo: {
      type: String,
      default: ''
    },
  },
  
}
</script>
<style scoped>
  .footer{display:flex; border-top: 2px solid #d1d1d1; margin-top:4rem;}
  .footLogo{width:10vw; display: flex; justify-content: center; align-items: flex-start; padding-top: 60px;}
  .footLogo img{width:80%;}
  .footContent{
    width: calc(100% - 10vw);
     padding: 3rem 2rem 2rem;
     display: flex; 
     justify-content: space-between;}
  .footContentLeft ul{margin-bottom:30px; display: inline-block;}
  .footContentLeft li{display: inline-block; margin:0 15px; font-weight: 600;}
  .footContentLeft li:first-child{margin:0 15px 0 0;}
  .footContentLeft li:last-child{margin:0 0 0 15px;}
  .footContentLeft p{font-weight:600; line-height: 1.3;}



  .footer .selectBox2 * { box-sizing: border-box; }
  .footer .selectBox2 {
  position: relative;
  width: 25vw;
  height: 5vh;
  border-radius: 4rem;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  cursor: pointer;
}
.footer .selectBox2 .label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  height: inherit;
  border: 0 none;
  outline: 0 none;
  padding: 0 2rem;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  transition: 0.3s;
}
.footer .selectBox2 .optionList {
  position: absolute; 
  bottom: 42px;
  left: 0;
  width: 100%;
  background: #fff;
  color: #000;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  max-height: 0px;
  transition: .1s ease-in;
  box-shadow: 0 -6px 10px rgba(0,0,0,0.04);
}
.footer .selectBox2.active{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 5px 10px -1px rgba(0,0,0,0.1);
}
.footer .selectBox2.active .label span{
  transform: rotate(180deg);
}
.footer .selectBox2.active .optionList {
  max-height: 500px;
}
.footer .selectBox2 .optionItem {
  border-bottom: 1px dashed #ffcfcf;
  padding: 15px 30px;
  transition: .1s;
  font-weight: 500;
}
.footer .selectBox2 .optionItem:hover {
  background: #ffcfcf;
}
.footer .selectBox2 .optionItem:last-child {
  border-bottom: 0 none;
}
.footer .selectBox2 .optionList::-webkit-scrollbar {width: 6px;}
.footer .selectBox2 .optionList::-webkit-scrollbar-track {background: transparent; }
.footer .selectBox2 .optionList::-webkit-scrollbar-thumb {background: #303030; border-radius: 45px;}
.footer .selectBox2 .optionList::-webkit-scrollbar-thumb:hover {background: #303030;}

</style>
