<template>
  <div class="brand">
    <div class="topBanner container">
      <h1 data-aos="fade-up" data-aos-duration="1500">{{ title }}</h1>
      <h2 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100" class="f7">{{ subTitle }}</h2>
    </div>
    <div class="groupCon container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
      <p class="pc"><img :src="pc_img"></p>
      <p class="mo"><img :src="mo_img"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandComponent',
  data(){
    return{
      title:"조직도",
      subTitle:"건강한 행복, 건강한 외식문화의 No.1 그룹이 되겠습니다.",
      pc_img: require('/public/img/group.jpg') ,
      mo_img: require('/public/img/m_group.jpg') ,
    }
  },
  


}
</script>
<style scoped> 
.groupCon p{text-align: center;}
.groupCon img{width:100%;}
</style>
