<template>
    <div class="marketing-policy-container">
      <div class="marketing-policy-head">
        <h2 class="marketing-policy-title">마케팅 정보 활용 동의</h2>
        <span class="marketing-policy-description">
          마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집이용을 거부하실 수 있으며,
          <br>거부 시에도 서비스를 이용하실 수 있으나,<br>
          동의를 거부한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수 있습니다.
        </span>
      </div>
      <div class="marketing-policy-table-container">
        <table class="marketing-policy-table">
          <thead>
            <tr>
              <th>개인정보 수집 항목</th>
              <th>개인정보 수집 이용 목적</th>
              <th>보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>이름, 휴대폰번호, 이메일</td>
              <td>
                - 이벤트 운영 및 광고성 정보 전송<br>
                - 서비스 관련 정보 전송
              </td>
              <td>이용자가 동의를 철회시까지 보유/이용</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MarketingPolicy'
  }
  </script>
  
  <style scoped>
  .marketing-policy-container {
    text-align: center;
    margin: 10% 0;
  }
  
  .marketing-policy-head {
    margin-bottom: 20px;
  }
  
  .marketing-policy-title {
    margin-bottom: 10px;
    font-size: 24px;
  }
  
  .marketing-policy-description {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .marketing-policy-table-container {
    margin: 0 auto;
  }
  
  .marketing-policy-table {
    width: 80%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .marketing-policy-table th,
  .marketing-policy-table td {
    border: 1px solid #dfe2e5;
    padding: 6px 13px;
    text-align: left;
  }
  
  .marketing-policy-table th {
    background-color: #f8f8f8;
    font-weight: bold;
  }
  </style>
  