<template>
  <div class="YouTube pt2 container">
    <ul class="FamilyChannelList aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
      <li v-for="(item, index) in Mediahub" :key="index" class="FamilyChannelListbox">
        <a :href="item.path" class="f7 b5">{{ item.name }}</a>
      </li>
    </ul>
    <h2 class="left f11 b9 pt4 pb4">JUSTIN YOUTUBE</h2>
    <div class="container">
      <div id="channel-header-tabs" class="container">
        <ul>
          <li :class="{ selected: activeType === 'corporate' }" @click="fetchVideos('corporate')">기업행사</li>
          <li :class="{ selected: activeType === 'party' }" @click="fetchVideos('party')">결혼/파티</li>
          <li :class="{ selected: activeType === 'recipe' }" @click="fetchVideos('recipe')">레시피</li>
          <li :class="{ selected: activeType === 'concept' }" @click="fetchVideos('concept')">컨셉영상</li>
          <li :class="{ selected: activeType === 'promotion' }" @click="fetchVideos('promotion')">홍보영상</li>
          <li :class="{ selected: activeType === 'short' }" @click="fetchVideos('short')">쇼츠</li>
        </ul>
      </div>
      <ul class="youtube-list">
        <li
          v-for="video in youtubeVod"
          :key="video.link"
          class="youtube-video-item"
          :class="{ shortVideo: activeType === 'short' }"
          @click="navigateTo(video.url)"
        >
          <img :src="video.img" :alt="video.title" class="video-thumbnail" />
          <p class="video-title">{{ video.title }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'YouTubeComponent',

  data() {
    return {
      youtubeVod: [],
      activeType: 'short', // 기본값을 'short'로 설정
    };
  },
  props: {
    Mediahub: Array,
  },

  created() {
    this.fetchVideos(this.activeType);
  },

  methods: {
    fetchVideos(type) {
      this.activeType = type;
      axios
        .post('https://justincatering.com/api/justinfood.php', {
          path: 'youtube',
          value: type,
        })
        .then((response) => {
          this.youtubeVod = response.data;
        })
        .catch((error) => {
          console.error('Error fetching videos:', error);
        });
    },
    navigateTo(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.youtube-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* 왼쪽 정렬 */
  padding: 0;
  list-style: none;
  margin-top: 5vh;
}

.youtube-video-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 23%;
  margin-right: 2.665%;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 4vh;
}
.youtube-video-item:nth-child(4n) {
  margin-right: 0;
}

.video-thumbnail {
  width: 100%;
  /* 16:9 비율 설정, 쇼츠 영상에서는 비율 제거 */
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 15px;
}

.shortVideo .video-thumbnail {
  aspect-ratio: auto; /* 쇼츠 영상에서는 비율을 자동으로 */
}

.video-title {
  font-size: 1.3rem;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 표시할 최대 줄 수 */
  overflow: hidden;
  text-overflow: ellipsis;
}

#channel-header-tabs ul {
  display: flex;
  padding: 0;
  list-style: none;
  justify-content: flex-start;
  margin-bottom: 20px;
}

#channel-header-tabs ul li {
  list-style: none;
  text-align: center;
  font-size: 16px;
  width: 120px;
  cursor: pointer;
  padding: 10px 0;
}

#channel-header-tabs .selected {
  border-bottom: 2px solid #909090;
  padding-bottom: 10px;
  color: #000;
  font-weight: 600;
}

@media (max-width: 768px) {
  .youtube-video-item {
    width: 32%;
    margin-right: 1.333%;
  }
  .youtube-video-item:nth-child(3n) {
    margin-right: 0;
  }
  .youtube-video-item:nth-child(4n) {
    margin-right: 1.333%;
  }
}

@media (max-width: 620px) {
  .youtube-video-item {
    width: 48%;
    margin-right: 2%;
  }
  .youtube-video-item:nth-child(2n) {
    margin-right: 0 !important;
  }
  .youtube-video-item:nth-child(3n) {
    margin-right: 2%;
  }
  #channel-header-tabs ul{

    flex-wrap: wrap !important;
    justify-content: flex-start !important;
}

}
@media (max-width: 400px) {
  .youtube-video-item {
    width: 100%;
    margin-right: 0;
  }
}
</style>
