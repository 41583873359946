<template>
  <div class="brandPage container pt1">
      <!-- Static Business Channel List -->
      <ul class="FamilyChannelList aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
          <li v-for="(item, index) in business" :key="index" class="FamilyChannelListbox">
              <a :href="item.path" class="f7 b5">{{ item.name }}</a>
          </li>
      </ul>

      <!-- Portfolio Section Title -->
      <h1 class="f11 b9 pb4 pt4">JUSTIN PORTFOLIO</h1>

      <!-- Portfolio Items Display -->
      <ul class="PortfolioComponent PortfolioButton">
          <li v-for="(image, index) in pagePosts" :key="index" class="PortfolioList" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
              <a :href="image.link" target="_blank">
                  <div class="Portfolio_cover" :style="{ backgroundImage: 'url(' + image.list_thumbnail + ')' }"></div>
                  <div class="PortfolioPost">
                      <h3 class="f5 b8">{{ image.title }}</h3>
                      <div class="PortfolioPost_info">
                          <div class="btn01 f2" style="background: #f4f4f4; width:50%;">VIEW MORE</div>
                      </div>
                  </div>
              </a>
          </li>
      </ul>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PortfolioComponent',
  props: {
    business: Array,
  },
  data() {
    return {
      posts: [], // All posts will be stored here
      pagePosts: [], // Posts to be displayed
    }
  },
  methods: {
    // Fetch and update posts on component mount
    updatePostsDisplay() {
      this.pagePosts = this.posts; // Directly assign all posts to pagePosts
    },
  },
  mounted() {
    axios.post('https://justincatering.com/api/justinfood.php', { path: 'portfolio', value: 'post' })
      .then((response) => {
        this.posts = response.data;
        this.updatePostsDisplay(); // Update posts display
      }).catch(error => console.error("Error fetching data: ", error));
  },
}
</script>

<style scoped>
.Portfolio_slide{
    width:15rem;
    aspect-ratio: 1 / 0.1;
    position: absolute;
    top: -4rem;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
#page_value{
    width: 30px;
    text-align: center;
}
.Portfolio_slide .carousel__prev, .Portfolio_slide .carousel__next{
  height:63px; width: 63px; border-radius: 50%; border: none; box-shadow: 0 0 29px rgb(0 0 0 / 7%);
  
}
.Portfolio_slide .carousel__prev{background:#fff url("/public/img/prev.png") no-repeat center; transition: 0.3s;}
.Portfolio_slide .carousel__next{background:#fff url("/public/img/next.png") no-repeat center; transition: 0.3s;}

.Portfolio_slide .carousel__prev:hover{background-color: #ffcfcf;}
.Portfolio_slide .carousel__next:hover{background-color: #ffcfcf;}
.Portfolio_slide .carousel__icon{display: none;}

.Portfolio_slide .carousel__item {
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 10px rgb(0 0 0 / 7%);
}



/* */
.PortfolioList{
    /* 그림자 */
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    margin-bottom:6vh;
    border-radius: 1rem;
}

.Portfolio_cover {
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
width: 100%;
/* 비율 1:1 */
aspect-ratio: 1/0.7;


border-radius: 1rem 1rem 0 0;
}
.PortfolioComponent{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.PortfolioComponent .PortfolioList {width:47%;}
.PortfolioComponent .PortfolioList img{width:100%;}




.PortfolioPost{

padding: 1rem;
border-radius: 0 0 1rem 1rem;

}
.PortfolioPost h3{
/* 2줄뒤 ... 처리 */
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;

line-height: 1.3rem;
}
.PortfolioPost_info{

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0.5rem 0;
}
.PortfolioPost_info p:hover{
color:#ffcfcf;
}


@media screen and (max-width: 990px) {
  #app .PortfolioModal{
    padding:2rem;
    width: calc(100% - 4rem);
  }
  .Portfolio_slide{
  width: 20%;
  height:40px;
    top:-4rem;
  }
  .businessView > ul{
    width:100%; 
    margin-top: calc(5% + 4rem);
  }
  
  .Portfolio_slide .carousel__prev, .Portfolio_slide .carousel__next{
  height:20px; width: 20px; 
  background-size: contain !important;
}

 
  .Portfolio_slide{
    margin-bottom:5%;
  }
/*
.businessFilter .businessFilterCover

*/

}
@media screen and (max-width: 660px) {
  .Portfolio_slide{
    width:30%;
  }
}
@media screen and (max-width: 480px) {
  .Portfolio_slide{
    width:100%;
  }
  .PortfolioComponent .PortfolioList{
    width:100%;
  }
}
</style>
