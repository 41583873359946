<template>
  <ul class="newsModal newsComponent">
    <li class="news-content">
      <h2>{{ selectPosts.news_title}}</h2>
      <p>{{ selectPosts.news_des || '' }}</p>
      <p>{{ selectPosts.news_des02 || '' }}</p>
      <img :src="'https://justincatering.com' + (selectPosts.news_thumb ? selectPosts.news_thumb.replace('../', '/') : '')" alt="뉴스 이미지">
      <p>{{ selectPosts.time || '' }}</p>
    </li>
    <button style="width:10rem; border:0; back-ground-color:#fff; " class="btn01" @click="closeModal">닫기</button>
  </ul>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: {
    show: Boolean,
    closeModal: Function,
    selectPosts: Object,
//
  },
  data(){
      return{
        NewsModalTxt:'닫기',
      }
      
    }
}
</script>

<style>
.newsModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 60%;

  margin: 40px auto;
  position: relative;
}

.news-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.news-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.news-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.news-content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

</style>