<template>
  <ul class="MagazineComponent">
    <li class="TopContents">
      <Carousel :items-to-show="3" snap-align="center" style="width: 100%">
        <Slide v-for="(image, index) in posts" :key="index" >
          <div data-aos="fade-up" data-aos-duration="1500" :data-aos-delay="index+2+'00'" class="MagazineComponent_cover" @click="selectChange" :style="{ backgroundImage: 'url(' + encodeURI('https://justincatering.com' + image.post_thumb.replace('../', '/')) + ')' }"></div>
          <h3 data-aos="fade-up" data-aos-duration="1500" :data-aos-delay="index+2+'00'" class="f5 b6"  @click="selectChange">{{image.magazine }}</h3>
          <span data-aos="fade-up" data-aos-duration="1500" :data-aos-delay="index+2+'00'" @click="selectChange">{{ getFormattedDate(image.time) }}</span>
          <span data-aos="fade-up" data-aos-duration="1500" :data-aos-delay="index+2+'00'" class="MagazineComponent_idx">{{image.idx }}</span>
          
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </li>
  </ul>

  <ul  class="magazineComponent_content">
    <li class="magazineComponent_filter">
       <select class="b6 f5" :v-model="selectedIdx" @change="setSelectedIdx" ref="select">
           <option  v-for="image in posts" :key="image.id" :value="image.idx" >{{image.magazine }} </option>
       </select>

                                               
    </li>
    <li>

      <section>
        
         
            <h2 class="b6">{{ this.selectedIdx.post_title }}</h2>
            
            <br><br>
            
            <img :src="'https://justincatering.com' + (this.selectedIdx.post_thumb || '').replace('../', '/')" alt="">
            <div class="postDes" v-html="selectedIdx.post_des"></div>

            <img :src="'https://justincatering.com' + (this.selectedIdx.post_thumb02 || '').replace('../', '/')" alt="">
            <div class="MagazineTag" v-html="this.selectedIdx.tag || ''"></div>
     
      </section>
                  
    </li>

  </ul>
</template>

<script>
import axios from 'axios';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide,  Navigation } from 'vue3-carousel' //Pagination,
export default {
  name: 'MagazineComponent',
  components: {
    Carousel,
    Slide,
  //  Pagination,
    Navigation,
  },
  created(){
    axios.post('https://justincatering.com/api/justinfood.php',{ path: 'magazine',value:'post' }).then(response => {
      this.posts = response.data;
     
      if (this.selectedIdx.length == 0) {
        this.selectedIdx = this.posts[0];
      }
    
    })



  },
  mounted() {
    this.$nextTick(() => {
      const elements = document.querySelectorAll('.magazineComponent_content');
      setTimeout(() => {
        elements.forEach(element => {
          element.style.display = 'block';
        });
      }, 1000);
    });
  },
  data() {
    return {
      posts: [],
      selectedIdx: [],
    }
  }
  ,methods: {
    selectChange(e){
      //클릭시 .MagazineComponent_idx 값 가져오기
      const idx = e.target.parentNode.querySelector('.MagazineComponent_idx').innerText;
      this.$refs.select.value = idx;
      this.selectedIdx = this.$refs.select.value;
      this.$forceUpdate();

      this.posts.forEach(image => {
          
          if (image.idx == idx ) {
            this.selectedIdx  = image;
        
          }
        });


    },
    getFormattedDate(time) {
  const timestamp = Date.parse(time); // 타임스탬프로 변환
  if (isNaN(timestamp)) return ''; // NaN 검사

  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}.${month}.${day}`;
}
,
    setSelectedIdx(e) {

        this.posts.forEach(image => {
          
          if (image.idx == e.target.value ) {
            this.selectedIdx  = image;
        
          }
        });
    },
    divideText(text) {
        const maxLength = 300; // 일정 길이
        const dividedText = [];
        let tempText = "";

        text.split(". ").forEach((sentence) => { // .이 끝나는 문장기준으로 분할
        if (tempText.length + sentence.length >= maxLength) {
          dividedText.push(tempText);
          tempText = "";
        }
        //문장이 마지막이 아니면
        if (sentence !== text.split(". ")[text.split(". ").length - 1]) {
          tempText += sentence + ". ";
        } else {
          tempText += sentence;
        }

      });

      if (tempText) {
        dividedText.push(tempText);
      }

      return dividedText;
  },

  },

  
}
</script>

<style scoped>
.magazineComponent_content{display:none}
.businessView > ul{
  margin-top:8vh;
}
.TopContents{
  display: flex;
  width: 100%;
}

.TopContents img{
  width: 100%;
  height: auto;
}



.MagazineComponent_cover{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  width: 95%;
  aspect-ratio: 1/1;
 cursor: pointer;
  margin: 0 auto;
 
}

.MagazineComponent_cover + h3 , .MagazineComponent_cover + h3 + span{
  cursor: pointer;
}
.MagazineComponent_idx{
  display: none;
}
/* .MagazineComponent_cover 다음 요소*/


.Navigation {
  z-index: 10;
}
.carousel__slide {
  opacity: 1;
  transition: opacity 0.5s;
  display: flex;
   flex-direction: column;
}
.carousel__slide  h3, .carousel__slide  span{
  
  margin: 0;
 
  text-align: left;
  width:95% !important;
  margin:0 auto
  
}
.carousel__slide h3{
  padding: 0.5rem 0;

}

.magazineComponent_content{
  width:100%;
 
}
.magazineComponent_filter{
  margin: 0 auto;
  width: 45%;

  padding:20vh 0 10vh 0;
}
.magazineComponent_filter select{
 
  width: 100%;

  border: 0;
  /* 그림자 */
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  padding:1.5rem 2rem;

  color: #333;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23333'%3E%3Cpath d='M0 6l10 10L20 6H0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7rem top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.magazineComponent_content section{
  width: 80%;
  margin: 0 auto;
}

.magazineComponent_content section img{
  max-width: 100%;
  height: auto;
}
.MagazineTag{padding-bottom:4rem;}

@media screen and (max-width: 990px) {
  .businessView > ul{
    width: 100%;
  }
  #app .magazineComponent_filter {

padding: 7vh 0 7vh 0;
}
#app .BrandComponent .business{
  margin-bottom: 0;
}
}

@media screen and (max-width: 680px) {
    #app .magazineComponent_filter {

  padding: 3vh 0 3vh 0;
  }
  .magazineComponent_filter select{
    padding: 1rem 2rem;
    width: 100%;
  }
  .magazineComponent_filter{
    width: 80%;
  }
}


</style>